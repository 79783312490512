import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import { Context as HomepageContext } from '../context/HomepageContext';
import { Context as PlaylistContext } from '../context/PlaylistsContext'
import CustomLink from '../components/CustomLink';
import CustomImage from '../components/CustomImage';
import MetaTags from '../components/MetaTags';

const Home = () => {
  const {
    state: { heroBanner, topBanners, bottomBanners },
    getHeroBanner,
    getTopBanners,
    getBottomBanners
  } = useContext(HomepageContext);
  const { state: { playlistsCount, years }, getPlaylistsSummary } = useContext(PlaylistContext);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    getHeroBanner();
    getTopBanners();
    getBottomBanners();
    getPlaylistsSummary();
  }, []);

  return (
    <div className="content-page homepage">
      <MetaTags title="Bob Harris Archive" description="Bob Harris Archive" />
      <section className="header-section">
        <div className="content-wrapper">
          <div className="text">
            <h1 className="heading">{heroBanner?.title}</h1>
            <p className="paragraph">{heroBanner?.body}</p>
            {heroBanner?.button && heroBanner.link &&
              <CustomLink className="link link-black" type={heroBanner.link_type} url={heroBanner.link}>
                <button className="button">
                  {heroBanner.button}
                </button>
              </CustomLink>
            }
          </div>
        </div>
        {heroBanner?.image &&
          <CustomImage
            img={`homepage/${heroBanner?.image}`}
            classNameWrapper="img-container"
            classNameImg={imageLoaded ? 'img' : 'img-loading'}
            onLoad={() => setImageLoaded(true)}
            alt="" />
        }
      </section>
      <section className="top-banners-section">
        <div className="section-wrapper section-wrapper-green">
          {topBanners[0]?.section_title && <h2 className="section-heading heading-white">Bob Harris Shows | {playlistsCount} shows spanning {years} years of broadcasting</h2>}
          <ul className="list">
            {topBanners.length > 0 && topBanners.map((item, index) => {
              return (
                <li className={index === 0 ? 'item top-banner-big' : 'item top-banner-small'} key={item.id}>
                  <CustomLink className="link link-white" type={item.link_type} url={item.link}>
                    {item?.image &&
                      <CustomImage
                        img={`homepage/${item.image}`}
                        classNameWrapper="topBannersImageWrap"
                        classNameImg={index === 0 ? 'img-big' : 'img-small'}
                        alt="" />
                    }
                    <h1 className="heading heading-white">{item.title}</h1>
                  </CustomLink>
                </li>
              );
            })}
          </ul>
        </div>
      </section>
      <section className="bottom-banners-section">
        <div className="section-wrapper section-wrapper-green-dark">
          {bottomBanners[0]?.section_title && <h2 className="section-heading heading-white">{bottomBanners[0]?.section_title}</h2>}
          <ul className="list">
            {bottomBanners.length > 0 && bottomBanners.map((item, index) => (
              <li className="item" key={item.id}>
                <CustomLink className="link link-white" type={item.link_type} url={item.link}>
                  {item?.image &&
                    <CustomImage
                      img={`homepage/${item.image}`}
                      classNameWrapper="img-container"
                      classNameImg="img"
                      alt="" />
                  }
                  <h1 className="heading heading-white">{item.title}</h1>
                </CustomLink>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </div>
  );
};

export default Home;