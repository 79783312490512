import React from 'react';

const Terms = () => {
  return (
     <div className="content-page terms">
      <section className="terms-section">
        <div className="section-wrapper section-wrapper-full-height section-wrapper-green">
          <h1 className="section-heading heading-white">Terms of Use</h1>
          <p>
            The contents of this site, and communications between this site and its users, are protected by database right, copyright, 
            confidentiality and the right not to be intercepted conferred by section 1(3) of the Regulation of Investigatory Powers Act 2000. 
            The use of those contents and communications by Internet Service Providers or others to profile or classify users of this site 
            for advertising or other purposes is strictly forbidden.
          </p>
          <h3>Website disclaimer</h3>
          <h3>(1) Introduction</h3>
          <p>
            This disclaimer governs your use of our website; by using our website, you accept this disclaimer in full. If you disagree with 
            any part of this disclaimer, you must not use our website.
          </p>
          <h3>(2) Intellectual property rights</h3>
          <p>
            Unless otherwise stated, we or our licensors own the intellectual property rights in the website and material on the website. 
            Subject to the licence below, all these intellectual property rights are reserved.
          </p>
          <h3>(3) Licence to use website</h3>
          <p>
            You may view, download for caching purposes only, and print pages and images from the website for your own personal use, subject
             to the restrictions below.
          </p>
          <p>You must not:</p>
          <p>(a) republish material from this website (including republication on another website);</p>
          <p>(b) sell, rent or otherwise sub-license material from the website;</p>
          <p>(c) show any material from the website in public;</p>
          <p>(d) reproduce, duplicate, copy or otherwise exploit material on our website for a commercial purpose;</p>
          <p>(e) edit or otherwise modify any material on the website; or</p>
          <p>(f) redistribute material from this website.</p>
          <h3>(4) Limitations of warranties and liability</h3>
          <p>
            Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; 
            nor do we commit to ensuring that the website remains available or that the material on the website is kept up-to-date.
          </p>
          <p>
            To the maximum extent permitted by applicable law we exclude all representations, warranties and conditions relating to this website 
            and the use of this website (including, without limitation, any warranties implied by law of satisfactory quality, fitness for purpose and/or 
              the use of reasonable care and skill).
          </p>
          <p>
            Nothing in this disclaimer (or elsewhere on our website) will exclude or limit our liability for fraud, for death or personal injury caused 
            by our negligence, or for any other liability which cannot be excluded or limited under applicable law.
          </p>
          <p>
            Subject to this, our liability to you in relation to the use of our website or under or in connection with this disclaimer, whether in contract, 
            tort (including negligence) or otherwise, will be limited as follows:
          </p>
          <p>
            (a) to the extent that the website and the information and services on the website are provided free-of-charge, we will not be liable for any loss
             or damage of any nature;
          </p>
          <p>(b) we will not be liable for any consequential, indirect or special loss or damage;</p>
          <p>
            (c) we will not be liable for any loss of profit, income, revenue, anticipated savings, contracts, business, goodwill, reputation, data, 
            or information.
          </p>          
          <h3>(5) Variation</h3>
          <p>
            We may revise these terms of use from time-to-time. Revised terms of use will apply to the use of our website from the date of the publication 
            of the revised terms of use on our website. Please check this page regularly to ensure you are familiar with the current version.
          </p>
          <h3>(6) Entire agreement</h3>
          <p>This disclaimer, together with our privacy policy,</p>
          <p>
            constitutes the entire agreement between you and us in relation to your use of our website, and supersedes all previous agreements in respect 
            of your use of this website.
          </p>
          <h3>(7) Law and jurisdiction</h3>
          <p>
            This disclaimer will be governed by and construed in accordance with English law, and any disputes relating to this disclaimer will be subject 
            to the exclusive jurisdiction of the courts of England and Wales.
          </p>
          <p>You can contact us by email to admin@bobharris.org.</p>
          <p>
            This website disclaimer is based on a template created by <a href="https://www.website-contracts.co.uk/" rel="noopener noreferrer" target="_blank">Website-contracts.co.uk</a> 
            and distributed
            by <a href="https://seqlegal.com/legal-services/website-law" rel="noopener noreferrer" target="_blank">Website-law.co.uk</a>
          </p>
        </div>
      </section>
     </div>
  );
};

export default Terms;