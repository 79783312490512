import React from 'react';
import CustomLink from '../components/CustomLink';

const BuyButton = props => {
    const createMarkup = () => {
        return {__html: props.playlistIntro?.alternative_purchase_text ? props.playlistIntro.alternative_purchase_text : 'AN AUTOGRAPHED COPY OF THIS PLAYLIST IS AVAILABLE TO PURCHASE<br>CLICK TO HERE TO MAKE AN ENQUIRY'};
    }
    return (
        <>
            {(props.playlistIntro?.purchase_button == 1 && props.playlistIntro.purchase_link) ?
                <CustomLink className="link link-black" type="external" url={props.playlistIntro.purchase_link}>
                    <button className="button">
                        {props.playlistIntro.purchase_text}
                    </button>
                </CustomLink>
                :
                <CustomLink className="link link-black" type="external" url='https://www.bobharris.org/playlist-enquiry-form'>
                    <button className="button" dangerouslySetInnerHTML={createMarkup()}>
                    </button>
                </CustomLink>
            }
        </>
    )
};

export default BuyButton;
