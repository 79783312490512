import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoIosArrowDown, IoIosArrowUp, IoMdImages } from 'react-icons/io';
import ImageGallery from 'react-image-gallery';

import MetaTags from '../components/MetaTags';
import CustomImage from '../components/CustomImage';
import { Context as ArtistsContext } from '../context/ArtistsContext';
import { Context as SessionsContext } from '../context/SessionsContext';
import ArtistSocialLinks from '../components/ArtistSocialLinks';
import YoutubeVideo from '../components/YoutubeVideo';
import { formatProgrammeName } from '../helpers/formatProgrammeName';
import { urlPrettify } from '../helpers/urlPrettify';
import { formatDate } from '../helpers/formatDate';
import ArtistSocialButtonsList from '../components/ArtistSocialButtonsList';

const Artist = (props) => {
  const {
    state: { artist, relatedArtist, artistPlaylistResults, artistPlaylistResultsCount, artistPlaylistProgrammes, },
    getArtist,
    getRelatedArtist,
    getPlaylistResultsArtist,
    resetArtistsState
  } = useContext(ArtistsContext);
  const { state: { session }, getSession } = useContext(SessionsContext);
  const [programmeName, setProgrammeName] = useState('');
  const [artistArray, setArtistArray] = useState([]);
  const [page, setPage] = useState(1);
  const [showDates, setShowDates] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const id = props.match.params.id;
    getRelatedArtist(id).then(async (res) => {
      setArtistArray([...res]);
    });
    getArtist(id)
      .then(response => {
        if (response.artistImages.length > 0) {
          setImages(response.artistImages.map(item => {
            return {
              original: `${process.env.REACT_APP_API_BASE_URL}/public/images/artists/artist_gallery/${item.image}`,
              thumbnail: `${process.env.REACT_APP_API_BASE_URL}/public/images/artists/artist_gallery/${item.image}`,
              description: item.caption
            }
          }))
        }
      });
    getPlaylistResultsArtist(id, page);

    return () => {
      resetArtistsState();
    };
  }, []);

  const pageClickHandler = data => {
    if (page === `${data.selected} + 1`) {
      return;
    }
    setPage(data.selected + 1);
    window.scrollTo(0, 0);
    const id = props.match.params.id;
    getPlaylistResultsArtist(id, data.selected + 1);
  };

  const toggleDatesList = (key) => {
    if (showDates.includes(key)) {
      setShowDates(showDates.filter(item => item !== key));
    } else {
      setShowDates([...showDates, key]);
    }
  };

  const albumMusicShareHandler = (albumId) => {
   document.querySelector(`.album-${albumId}`).classList.toggle('show')
  };

  return (
    <div className="content-page artist">
      <MetaTags
        title={`${artist?.name} | Artist | Bob Harris Archive`}
        description={`${artist?.name} | Artist | Bob Harris Archive`}
      />
      <section className="artist-section">
        <div className="section-wrapper section-wrapper-full-height section-wrapper-green">
          <div className="helper-container">
            <div className="left-column">
              <h2 className="section-heading heading-white">
                <span>Artists</span>
                <span className="slash">/</span>
                <span>{artist?.name}</span>
              </h2>
              <div className="intro">
                {artist?.image && <div className="img-container">
                  <img
                    className="img"
                    src={`${process.env.REACT_APP_API_BASE_URL}/public/images/artists/main/${artist.image}`}
                    alt={artist.image} />
                </div>
                }
                {artist?.freetext && <div className="bio">
                  <p className="paragraph">{artist.freetext}</p>
                </div>
                }
              </div>
              {artist?.youtube &&
                <YoutubeVideo videoId={artist.youtube} />
              }
              {artist && <ArtistSocialLinks
                size={22}
                website={artist.url}
                facebook={artist.facebook}
                twitter={artist.twitter}
                youtube={artist.youtube}
                artistName={artist.name}
              />
              }
              {artist?.artistImages.length > 0 &&
                <ImageGallery
                  showPlayButton={false}
                  showIndex={true}
                  onSlide={(currentIndex) => console.log(currentIndex)}
                  items={images}
                  additionalClass="gallery-slider" />
              }
              {artistArray && artistArray.length > 0 &&
                <div className="related-artists">
                  <h2 className="heading heading-white"><span>Associated acts :</span></h2>
                  <ul className="related-artists-list">
                    {artistArray.map((item, index) => {
                      return (
                        <li key={index} className="item">
                          {/* Not using link component because it won't switch pages just change the URL */}
                          <a
                            className="link"
                            href={`/artist/${urlPrettify(item.name).replace('/', '')}/${item.artistid}`}
                          >
                            <div className="img-container">
                              {item.wwwimage ?
                                <img
                                  className="img"
                                  src={`${process.env.REACT_APP_API_BASE_URL}/public/images/artists/covers/original/${item.wwwimage}`}
                                  alt="" /> :
                                <div className="img-container">
                                  <IoMdImages size={76} className="placeholder-icon" />
                                </div>
                              }
                              <div className="item-info">
                                <span className="primary">{item.name}</span>
                              </div>
                            </div>
                          </a>
                        </li>
                      );
                    })
                    }
                  </ul>
                </div>
              }
            </div>
            <div className="right-column">
              <h2 className="section-heading heading-white">
                Most played tracks
              </h2>
              {Object.keys(artistPlaylistResults).length > 0 ?
                <ul className="list">
                  <li className="list-header">
                    <span>Album / Source</span>
                    <span>Track</span>
                    <span>Date played</span>
                  </li>
                  {Object.entries(artistPlaylistResults).map(([albumKey, albumValue]) => {
                    if (Object.keys(albumValue).length === 0) {
                      return <div></div>;
                    }
                    const subItem = albumValue[Object.keys(albumValue)[0]][0];
                    return (
                      <li key={albumKey} className="list-row">
                        <div className="list-cell album-cell">
                          <span className="label">Album / Source</span>
                          <div className="value">
                            {subItem.album.coverscan ?
                              <img
                                onError={(e) => e.target.src = `${process.env.REACT_APP_API_BASE_URL}/public/images/albums/default_album.png`}
                                className="album-cover"
                                src={`${process.env.REACT_APP_API_BASE_URL}/public/images/albums/original/${subItem.album.coverscan}`}
                                alt={subItem.album.title}
                              /> :
                              <img
                                className="album-cover"
                                src={`${process.env.REACT_APP_API_BASE_URL}/public/images/albums/default_album.png`}
                                alt={subItem.album.title}
                              />
                            }
                            <p className="title">{subItem.album.title}</p>
                            <div className="album-share-wrapper">
                              <button className="share-button" onClick={() => albumMusicShareHandler(albumKey)}>buy / stream</button>
                              <div className={`list-wrapper album-${albumKey} album-share-buttons`}>
                                <ul className="list" onClick={() => albumMusicShareHandler(albumKey)}>
                                  <ArtistSocialButtonsList artistName={artist.name} path={subItem.album.title} />
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="list-cell track-container">
                          <span className="label">Tracks</span>
                          <span className="value">
                            <ul className="tracks-list">
                              {Object.entries(albumValue).map(([trackKey, trackValue]) => {
                                let programme = artistPlaylistProgrammes.find(p => p.shortcode === trackValue[0].progletter)?.Programme;
                                return (
                                  <li className="track-item" key={trackKey}>
                                    <span className="track">{trackValue[0].track.tracktitle}</span>
                                    <span className="date">
                                      {trackValue.length === 1 ?
                                        <Link
                                          className="link link-white"
                                          to={{
                                            pathname: `/${artistPlaylistProgrammes.length > 0 && programme && urlPrettify(programme)}/playlists/${trackValue[0].date}/${trackValue[0].progcode}`
                                          }}>
                                          {formatDate(trackValue[0].date)}
                                        </Link> :
                                        <div>
                                          <div onClick={() => toggleDatesList(trackKey)} className="expand-menu">
                                            <span>Show all dates</span>
                                            {showDates.includes(trackKey) ?
                                              <IoIosArrowUp className="caret" size={20} /> :
                                              <IoIosArrowDown className="caret" size={20} />
                                            }
                                          </div>
                                          <ul className={showDates.includes(trackKey) ? 'dates-list' : 'dates-list-hidden'}>
                                            {Object.entries(trackValue).map(([dateKey, dateValue]) => {
                                              programme = artistPlaylistProgrammes.find(p => p.shortcode === dateValue.progletter)?.Programme;
                                              return (
                                                <li key={dateKey} className="item">
                                                  <Link
                                                    className="link link-white"
                                                    to={{
                                                      pathname: `/${artistPlaylistProgrammes.length > 0 && urlPrettify(programme)}/playlists/${dateValue.date}/${dateValue.progcode}`
                                                    }}>
                                                    {formatDate(dateValue.date)}
                                                  </Link>
                                                </li>
                                              );
                                            })}
                                          </ul>
                                        </div>
                                      }
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </span>
                        </div>
                      </li>
                    )
                  })}
                </ul> :
                <p className="no-results">No playlists found</p>
              }
              {artistPlaylistResultsCount > 1 && <ReactPaginate
                pageCount={artistPlaylistResultsCount}
                previousLabel={'previous'}
                nextLabel={'next'}
                pageRangeDisplayed={4}
                onPageChange={pageClickHandler}
                containerClassName={'pagination-light'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                disableInitialCallback={true}
                forcePage={page - 1}
              />}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Artist;