import axios from 'axios';
import axiosRetry from 'axios-retry';

const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}/api`
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

axiosRetry(instance, { retries: 3 });

instance.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
    },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  (err) => {
    console.log(err)

    if (err.response && err.response.data.message === 'Unauthorized access') {
      window.location.href = '/login';
    }
    return Promise.reject(err);
  }
);

export default instance;