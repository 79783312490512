import React, { useContext } from 'react';
import { Context as ArtistsContext } from '../context/ArtistsContext';

import { AiFillAmazonCircle } from 'react-icons/ai';
import { FaNapster, FaYandexInternational, FaBandcamp, FaSoundcloud } from 'react-icons/fa';
import { SiYoutubetv, SiDeezer, SiApplemusic, SiSpotify, SiAmazon } from 'react-icons/si';
import ArtistSocialButton from './ArtistSocialButton';

const ArtistSocialButtonsList = ({ artistName = '', path = '' }) => {
  const size = 22;

  return (
    <>
      <ArtistSocialButton
        href={`https://music.youtube.com/search?q=${artistName} ${path}`}
        children={<SiYoutubetv size={size} className="icon" />}
        website={'Youtube Music'}
      />
      <ArtistSocialButton
        href={`https://open.spotify.com/search/${artistName} ${path}`}
        children={<SiSpotify size={size} className="icon" />}
        website={'Spotify'}
      />
      <ArtistSocialButton
        href={`https://music.apple.com/gb/search?term=${artistName} ${path}`}
        children={<SiApplemusic size={size} className="icon" />}
        website={'Apple Music'}
      />
      <ArtistSocialButton
        href={`https://www.amazon.co.uk/s?k=${artistName} ${path}`}
        children={<AiFillAmazonCircle size={size} className="icon" />}
        website={'Amazon'}
      />
      <ArtistSocialButton
        href={`https://music.amazon.co.uk/search/${artistName} ${path}`}
        children={<SiAmazon size={size} className="icon" />}
        website={'Amazon Music'}
      />
      <ArtistSocialButton
        href={`https://www.deezer.com/search/${artistName} ${path}`}
        children={<SiDeezer size={size} className="icon" />}
        website={'Deezer'}
      />
      <ArtistSocialButton
        href={`https://soundcloud.com/search?q=${artistName} ${path}`}
        children={<FaSoundcloud size={size} className="icon" />}
        website={'Sound Cloud'}
      />
      <ArtistSocialButton
        href={`https://gb.napster.com/search?query=${artistName} ${path}`}
        children={<FaNapster size={size} className="icon" />}
        website={'Napster'}
      />
      <ArtistSocialButton
        href={`https://music.yandex.com/search?text=${artistName} ${path}`}
        children={<FaYandexInternational size={size} className="icon" />}
        website={'Yandex'}
      />
      <ArtistSocialButton
        href={`https://bandcamp.com/search?item_type&q=${artistName} ${path}`}
        children={<FaBandcamp size={size} className="icon" />}
        website={'Bandcamp'}
      />
    </>
  );
}

export default ArtistSocialButtonsList;