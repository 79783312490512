import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useOktaAuth } from '@okta/okta-react';

import { Context as AuthContext } from '../../context/AuthContext';

const LogoutButton = () => {
  const { state: { username }, getUsername } = useContext(AuthContext);
  const { authState, authService } = useOktaAuth();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    if (authState.accessToken) {
      localStorage.setItem('token', authState.accessToken);
    } else {
      localStorage.removeItem('token');
    }

    if (!authState.isAuthenticated) {
      setUserInfo(null);
    } else {
      authService.getUser().then((info) => {
        if (info) {
          setUserInfo(info);
          localStorage.setItem('username', info.name);
          getUsername(info.name);
        }
      });
    }
  }, [authState, authService]);

  const logout = async () => {
    authService.logout('/');
    localStorage.removeItem('username');
    localStorage.removeItem('token');
    getUsername(null);
  };

  if (!authState.isAuthenticated) { 
    return <Fragment></Fragment>;
  }

  return (
    <Fragment>
      <a className="nav-link link link-white logout-btn" onClick={logout}>Logout</a>
    </Fragment>
  );

};

export default LogoutButton;



