import React from 'react';
import { AiFillRobot } from 'react-icons/ai';

const PageNotFound = () => {
  return (
    <div className="page-not-found">
      <div className="wrapper">
        <div className="left-column">
          <h1>404</h1>
          <div className="bottom-border">
            <h3>Looks like the page you were looking for cannot be found</h3>
          </div>
        </div>
        <div className="right-column">
          <AiFillRobot className="icon" />
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;