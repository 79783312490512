import React , { useState, useEffect, useContext, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import { GrImage } from 'react-icons/gr';
import { IoMdImages, IoMdTrash } from 'react-icons/io';
import { DotLoader } from 'react-spinners';

import { Context as ProgrammesContext } from '../../context/ProgrammesContext';
import { addNotification } from '../../helpers/addNotification';

const ProgrammeForm = ({ closeModal, currentItem }) => {
  const { 
    state: { programmes }, 
    getProgrammes, 
    saveProgramme, 
    updateProgramme,
    checkProgrammeAvailability
  } = useContext(ProgrammesContext);
  const [isSaving, setIsSaving] = useState(false);
  const [programme, setProgramme] = useState('');
  const [programmeRequired, setProgrammeRequired] = useState(false);
  const [programmeAvailable, setProgrammeAvailable] = useState(true);
  const [currentIntroImage, setCurrentIntroImage] = useState(null);
  const [newIntroImage, setNewIntroImage] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (currentItem) {
      if (currentItem.image) {
        setCurrentIntroImage(currentItem.image);
      }
      setProgramme(currentItem.Programme);
    } 
  }, [currentItem]);

  const fileChangedHandler = (event) => {
    setNewIntroImage(event.target.files[0]);
  };

  const deleteIntroImage = (type, image) => {
    if (type === 'new') {
      setNewIntroImage(null);
    }
    if (type === 'current') {
      setCurrentIntroImage(null);    
    }
  };

  const onSearchProgrammes = (text) => {
    setProgrammeRequired(text ? false : true);
    setProgramme(text);
    if (!text) {
      setProgrammeAvailable(true);
      return;
    }
    checkProgrammeAvailability(text)
      .then(response => {
        if (response) setProgrammeAvailable(response.programme ? true : false);
      });
  };

  const onBlur = () => {
    setProgrammeRequired(programme ? false : true);
  };

  const onSubmit = async (formData) => {
    if (!programme) {
      setProgrammeRequired(true);
      return;
    }
    if (!programmeAvailable) {
      return;
    }

    setIsSaving(true);

    let programmeData = new FormData();

    formData.Programme = programme;

    if (currentItem) {
      if (formData.Programme !== currentItem.Programme) {
        formData.programmeNameChanged = true;
      }
    }

    if (currentItem?.image && !currentIntroImage) {
      formData.image = '';
      programmeData.append('deletedIntroImage', currentItem.image);
    }

    Object.keys(formData).forEach(key => {
      programmeData.append(key, formData[key]);
    });

    if (newIntroImage) {
      programmeData.append(
        'introImage',
        newIntroImage,
        newIntroImage.name
      );
    }

    const actionFnc = currentItem ? updateProgramme : saveProgramme;
    let title, type, message;
    actionFnc(programmeData).then(response => {
      getProgrammes()
        .then(result => {
          closeModal();
          if (response.status === 200) {
            title = 'Success!';
            type = 'success';
            message = response.data.message;
            addNotification(title, message, type);
            setIsSaving(false);
          }
        })
        .catch(err => {
          setIsSaving(false);
          if (err.response.status === 422) {
            title = 'Error!'  
            type = 'danger';
            message = err.response.data.message;
            addNotification(title, message, type); 
          }
        });
    });
  };

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={currentItem}
      render={({ handleSubmit, form, submitting, pristine, reset }) => (
        <form className="dashboard-form" onSubmit={handleSubmit}>
          <Field name="Programme">
            {({ input, meta }) => (
              <div className="input-group">
                <div className="input-group-header">
                  <label htmlFor="programme" className="label">Programme Name</label>
                  {programmeRequired && <span className="error input-type">Required</span>}
                  {!programmeAvailable && <span className="error input-type">Another programme with that name already exists</span>}
                </div>
                <input 
                  { ...input }
                  onChange={(event) => onSearchProgrammes(event.target.value)} 
                  onBlur={() => onBlur()}
                  id="programme"
                  type="text" 
                  placeholder="Programme Name"
                  value={programme}
                />
              </div>
            )}
          </Field> 
          <div className="input-group">
            <div className="input-group-header">
              <label htmlFor="station" className="label">Radio Station</label>
              <span className="input-type"> Optional</span>
            </div>
            <Field name="radio_station" id="station" component="input" type="text" placeholder="Station" />
          </div>
          <div className="input-group">
            <div className="input-group-header">
              <label htmlFor="title" className="label">Title</label>
              <span className="input-type"> Optional</span>
            </div>
            <Field name="title" id="title" component="input" type="text" placeholder="Title" />
          </div>
          <div className="input-group">
            <div className="input-group-header">
              <label htmlFor="description" className="label">Description</label>
              <span className="input-type"> Optional</span>
            </div>
            <Field 
              name="description" 
              id="description" 
              component="textarea" 
              rows="5"
              placeholder="Description" />
          </div>
          <div className="input-group">
            <div className="input-group-header">
              <label htmlFor="introImage" className="label">Intro Image</label>
              <span className="input-type"> Optional</span>
            </div>
            {newIntroImage && 
              <div className="img-container img-container-big">         
                <div onClick={() => deleteIntroImage('new', newIntroImage)} className="delete-banner">
                  <IoMdTrash className="trash-icon" size={25} />
                  <div className="delete-banner-background"></div>
                </div>
                <img 
                  src={URL.createObjectURL(newIntroImage)} 
                  onClick={() => fileInputRef.current.click()} 
                  className="playlist-intro-image" 
                />
              </div>
            }
            {currentIntroImage && !newIntroImage &&
              <div className="img-container img-container-big">   
                <div onClick={() => deleteIntroImage('current', currentIntroImage)} className="delete-banner">
                  <IoMdTrash className="trash-icon" size={25} />
                  <div className="delete-banner-background"></div>
                </div>      
                <img 
                  src={`${process.env.REACT_APP_API_BASE_URL}/public/images/programmes/intro/${currentIntroImage}`} 
                  onClick={() => fileInputRef.current.click()}
                  className="playlist-intro-image" 
                />
              </div>
            }
            {!currentIntroImage && !newIntroImage && <div onClick={() => fileInputRef.current.click()} className="image-placeholder playlist-image-placeholder">
                <GrImage size={45} className="placeholder-icon" />
              </div>
            } 
            <Field name="image">
              {props => (
                <div className="input-group">
                  <input 
                    ref={fileInputRef}
                    className="file-input"
                    type="file" 
                    accept="image/*" 
                    id="introImage"
                    onChange={fileChangedHandler} />
                  <span className="instructions">
                    <p>
                      Image will be displayed at the top of a programme page in the programme introductory section.
                    </p>
                  </span>
                </div>
              )}
            </Field>
            <div className="input-group">
              <div className="input-group-header">
                <label htmlFor="imageCredits" className="label">Image credits</label>
                <span className="input-type"> Optional</span>
              </div>
              <Field 
                name="image_credits" 
                id="imageCredits" 
                component="input" 
                type="text" 
                placeholder="Image credits" 
              />
            </div>
          </div>
          <div className="buttons">
            <button
              className="button dismiss-button" 
              type="button"
              onClick={() => {
                form.reset();
              }}
              disabled={submitting || pristine}
            >
              Reset
            </button>
            <button 
              className={isSaving ? 'button action-button submit-button in-progress' : 'button action-button submit-button'} 
              type="submit" 
              disabled={submitting || isSaving}
            >
              {isSaving && <div className="sweet-loading">
                <DotLoader 
                  size={20}
                  color={"#fff"}
                  loading={isSaving}
                />
              </div>}
              <span>{isSaving ? 'Submitting...' : 'Submit'}</span>
            </button>
          </div>
        </form>
      )} />
    );
};

export default ProgrammeForm;