import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { MdAudiotrack } from 'react-icons/md';
import { IoMdMusicalNote } from 'react-icons/io';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import MetaTags from '../components/MetaTags';
import CustomImage from '../components/CustomImage';
import { Context as PlaylistsContext } from '../context/PlaylistsContext';
import { Context as ProgrammesContext } from '../context/ProgrammesContext';
import { urlPrettify } from '../helpers/urlPrettify';
import { formatProgrammeName } from '../helpers/formatProgrammeName';
import CustomModal from '../components/CustomModal';

const PlaylistsListProgramme = props => {
  const { 
    state: { 
      playlists, 
      playlistsCount, 
      playlistsYears, 
      mostPlayed, 
      mostPlayedCount,
      playlistsGroupByDate
    }, 
    getPlaylistsByYear, 
    resetPlaylistsState,
    getMostPlayed
  } = useContext(PlaylistsContext);
  const { getProgramme } = useContext(ProgrammesContext);
  const [page, setPage] = useState(1);
  const [activeYear, setActiveYear] = useState(null);
  const [programme, setProgramme] = useState(null);
  const [programmeName, setProgrammeName] = useState('');
  const [startDate, setStartDate] = useState(new Date('1998-01-01'));
  const [endDate, setEndDate] = useState(new Date());
  const [topTracks, setTopTracks] = useState([]);
  const [sliderSettings, setSliderSettings] = useState(null);
  const [lightboxOn, setLightboxOn] = useState(false);
  const lightboxImageRef = useRef(null);
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    pauseOnFocus: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutside);

    const { programme, shortcode, year } = props.match.params;
    setProgrammeName(formatProgrammeName(programme));
    setActiveYear(parseInt(year));
    
    getPlaylistsByYear(shortcode, year, 1);
    getProgramme(shortcode)
      .then(response => setProgramme(response));

    getMostPlayed('tracks', 16, [new Date(year), new Date(`${parseInt(year)+ 1}`)], shortcode, 1, 16)
      .then(response => {
        settings.slidesToShow = response.length > 4 ? 5 : response.length;
        settings.slidesToScroll = response.length > 4 ? 5 : response.length;
        settings.responsive[0].settings.slidesToShow = response.length > 3 ? 4 : response.length;
        settings.responsive[0].settings.slidesToScroll = response.length > 3 ? 4 : response.length;
        settings.responsive[1].settings.slidesToShow = response.length > 2 ? 3 : response.length;
        settings.responsive[1].settings.slidesToScroll = response.length > 2 ? 3 : response.length;
        settings.responsive[2].settings.slidesToShow = response.length > 1 ? 2 : response.length;
        settings.responsive[2].settings.slidesToScroll = response.length > 1 ? 2 : response.length;
        setSliderSettings({ ...settings });
        setTopTracks(response);
      });

    return () => {
      resetPlaylistsState();
      document.removeEventListener('mousedown', onClickOutside);
    }

  }, []);

  useEffect(() => {
    if (history.action === 'POP') {
      const { year, shortcode, page } = props.match.params;
      setActiveYear(parseInt(year));
      setPage(page);
      getPlaylistsByYear(shortcode, year, page);
    }
  }, [pathname]);

  const setActiveYearHandler = (year) => {
    const { programme, shortcode } = props.match.params;
    history.push(`/programmes/${programme}/${shortcode}/${year}`);
    setActiveYear(year);
    setPage(1);
    getPlaylistsByYear(shortcode, year, 1);
    getMostPlayed('tracks', 16, [new Date(year), new Date(`${parseInt(year)+ 1}`)], shortcode, 1, 16)
      .then(response => {
        setTopTracks(response);
      });
  };

  const onClickOutside = (e) => {
    if (e.target === lightboxImageRef.current) return;
    setLightboxOn(false);
  };

  const openModal = () => {
    setLightboxOn(true);
  };

  const closeModalHandler = () => {
    setLightboxOn(false);
  };

  const renderLeftColumn = () => {
    if (!programme) return;
    if (!programme.radio_station) return;

    return (
      <div className="left-column">
        <div className="intro">
          {programme.image && 
            <CustomImage 
              onClick={openModal}
              img={`programmes/intro/${programme.image}`}
              classNameWrapper={window.CSS && CSS.supports && CSS.supports('object-fit', 'cover') ? '' : 'img-container'}
              classNameImg='img'
              alt={programme.image} />
          }
          <div className="text">
            {programme.image_credits && <h3 className="credits image-credits heading-white">{programme.image_credits}</h3>}
            {programme.title && <h2 className="heading heading-white">{programme.title}</h2>}
            <h3 className="heading heading-white radio-station">{programme.radio_station}</h3>
            {programme.description && <p className="description">{programme.description}</p>}
          </div>
        </div>
      </div>
    );
  };

  const renderRightColumn = () => {
    let rightColumnClass;

    if (programme && programme.radio_station) {
      rightColumnClass ="right-column";
    } else {
      rightColumnClass = "right-column-no-intro";
    }

    return (
       <div className={rightColumnClass}>
          <div className="years-wrapper">
            <ul className="years-list">
              {playlistsYears && playlistsYears.map((item, index) => (
                <li className={activeYear === item ? 'active item' : 'item'} onClick={() => setActiveYearHandler(item)} key={item}>
                  {item}
                </li>
              ))}
            </ul>
          </div>
           <ul className="months-list">
            {Object.keys(playlistsGroupByDate).map((key, value) => {
              return (
                <li className="months-item" key={value}>
                  <span className="month">{key}</span>
                  <ul className="dates-list">
                    {playlistsGroupByDate[key].map((item, index) => (
                      <li className="dates-item" key={index}>
                        <Link 
                          className="link link-white dates-link" 
                          to={{ pathname: `/${props.match.params.programme}/playlists/${item.date}/${item.progcode}` }}>
                          <span className="date">{item.date.split('-')[2]}</span>   
                          <span className="playlist-title">                       
                            <span className="truncate-text">
                              {item.intro}
                            </span>
                          </span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </div>
    );
  };

  return (
    <div className="content-page playlists-list">
      <MetaTags title={`${programmeName} | Bob Harris Archive`} description={`${programmeName} | Bob Harris Archive`} />
      <section className="playlists-list-section">
        <div className="section-wrapper section-wrapper-full-height section-wrapper-green">
          <CustomModal
            handleClose={closeModalHandler}
            show={lightboxOn}>
              <img 
                ref={lightboxImageRef}
                className="img" 
                src={`${process.env.REACT_APP_API_BASE_URL}/public/images/programmes/intro/${programme?.image}`} 
                alt={programme?.image} />
          </CustomModal>
          <h2 className="section-heading heading-white">{programmeName}
            <span className="slash">/</span> 
            <span>Playlists</span>
            <span className="slash">/</span> 
            <span>{activeYear}</span>
          </h2>
          <div className="helper-container">
            {renderLeftColumn()}
            {renderRightColumn()}
          </div>
        </div>
      </section>
      {sliderSettings && topTracks && <section className="most-played-tracks">
          <div className="section-wrapper section-wrapper-green-dark">
            <h2 className="section-heading heading-white">
              <span>Most Played Tracks in {activeYear}</span>
            </h2>
            <Slider { ...sliderSettings }>
              {topTracks && topTracks.map((item, index) => {
                let smallImageName;
                if (item.artist.wwwimage) {
                  const imageNameParts = item.artist.wwwimage.split('.');
                  const modifiedExtension = `-small.${imageNameParts[imageNameParts.length - 1]}`;
                  imageNameParts.splice(-1, 1);
                  const joinParts = imageNameParts.join('.');
                  smallImageName = `${joinParts}${modifiedExtension}`;
                }
                return (
                  <div key={index} className="slider-item"> 
                    <Link 
                      className="image-link"
                      to={{ pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`, data: item}}
                    >
                    {item.artist.wwwimage ? 
                      <CustomImage 
                        img={`artists/covers/small/${smallImageName}`}
                        classNameWrapper="img-container"
                        classNameImg="img" 
                        alt={item.artist.name} /> :
                      <div className="placeholder-img">
                        <IoMdMusicalNote className="placeholder-icon" size={60} />
                      </div>
                    }
                    </Link>
                    <Link className="description" to={{ pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`, data: item}}>
                      <span className="track-name">{item.track.tracktitle}</span>
                    </Link>
                  </div>
                );
              })}
            </Slider>
          </div>
        </section>
      }
    </div>
  );
};

export default PlaylistsListProgramme;