import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { IoMdMusicalNote } from 'react-icons/io';
import DOMPurify from "dompurify";

import MetaTags from '../components/MetaTags';
import CustomImage from '../components/CustomImage';
import { Context as PlaylistsContext } from '../context/PlaylistsContext';
import { Context as ProgrammeContext } from '../context/ProgrammesContext'; // To get the radio name
import { urlPrettify } from '../helpers/urlPrettify';
import { formatProgrammeName } from '../helpers/formatProgrammeName';
import { formatDate, formatDateLong } from '../helpers/formatDate';
import CustomModal from '../components/CustomModal';
import CustomLink from '../components/CustomLink';
import BuyButton from '../components/BuyButton';

const PlaylistProgramme = props => {
  const {
    state: { playlist, playlistIntro, morePlaylists },
    getPlaylist,
    getProgramme, // Needed to get the radio station from the programme
    resetPlaylistsState
  } = useContext(PlaylistsContext);
  const [programmeName, setProgrammeName] = useState('');
  const [radioStation, setradioStation] = useState('');
  const [playlistDate, setPlaylistDate] = useState(null);
  const [lightboxOn, setLightboxOn] = useState(false);
  const lightboxImageRef = useRef(null);
  // RegExp variable to check if there are HTML elements on the descriptions
  const htmlRegex = new RegExp(/<\/?[a-z][\s\S]*>/);

  useEffect(() => {
    const { programme, progcode } = props.match.params;
    setProgrammeName(formatProgrammeName(programme));
    document.addEventListener('mousedown', onClickOutside);

    return () => {
      resetPlaylistsState();
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, []);

  useEffect(() => {
    const { progcode, date } = props.match.params;
    const shortcode = progcode.slice(0, -8);
    getProgramme(shortcode).then(data => // Catch the promise and set the programme data
      setradioStation(data.radio_station)
    )
    getPlaylist(progcode, date);
    setPlaylistDate(date);
  }, [props.match.params.progcode]);

  // Function that sanitizes the HTML from the db and creates the object to be rendered by React
  const createMarkup = () => {
    return {
      __html: DOMPurify.sanitize(playlistIntro.description, { ALLOWED_ATTR: ['href', 'target'] }),
    };
  };

  const openModal = () => {
    setLightboxOn(true);
  };

  const closeModalHandler = () => {
    setLightboxOn(false);
  };

  const onClickOutside = (e) => {
    if (e.target === lightboxImageRef.current) return;
    setLightboxOn(false);
  };

  const renderLeftColumn = () => {

    return (

      <div className="left-column">
        <div className="intro">
          {playlistIntro ?
            <>
              {playlistIntro.image &&
                <CustomImage
                  onClick={openModal}
                  img={`playlists/intro/${playlistIntro.image}`}
                  classNameWrapper={window.CSS && CSS.supports && CSS.supports('object-fit', 'cover') ? '' : 'img-container'}
                  classNameImg='img'
                  alt={playlistIntro.image} />
              }
              {/* If there is title or description display them */}
              {(playlistIntro.title || playlistIntro.description) ?
                <div className="text">
                  {playlistIntro.image_credits && <h3 className="credits image-credits heading-white">{playlistIntro.image_credits}</h3>}
                  <h2 className="heading heading-white">{playlistIntro.title}</h2>
                  <BuyButton playlistIntro={playlistIntro}></BuyButton>
                  {playlistIntro.description && (
                    <div>
                      {/* Check if the description has HTML tags, if so render it as HTML, if not print the plain text inside a <p></p> tag */}
                      {htmlRegex.test(playlistIntro.description) ? (
                        <div
                          className="description"
                          dangerouslySetInnerHTML={createMarkup()}></div>
                      ) : (
                        <p className="description">{playlistIntro.description}</p>
                      )}
                    </div>
                  )}
                  {playlistIntro.description_credits && <h3 className="credits description-credits heading-white">{playlistIntro.description_credits}</h3>}
                </div>
                :
                <>
                    <BuyButton playlistIntro={playlistIntro}></BuyButton>
                </>
              }
              {/* If there is no title or description but there is a purchase button, display the purchase button */}
            </>
            :
              <BuyButton playlistIntro={playlistIntro}></BuyButton>
          }
        </div>
      </div>
    );
  };

  return (
    <div className="content-page playlist-programme">
      <MetaTags
        title={`${programmeName} | ${formatDate(playlistDate)} | Bob Harris Archive`}
        description={`${programmeName} | ${formatDate(playlistDate)} | Bob Harris Archive`}
      />
      <section className="playlist-section">
        <div className="section-wrapper section-wrapper-full-height section-wrapper-green">
          <CustomModal
            handleClose={closeModalHandler}
            show={lightboxOn}>
            <img
              ref={lightboxImageRef}
              className="img"
              src={`${process.env.REACT_APP_API_BASE_URL}/public/images/playlists/intro/${playlistIntro?.image}`}
              alt={playlistIntro?.image} />
          </CustomModal>
          <h2 className="section-heading heading-white">{programmeName}
            <span className="slash">/</span>
            <Link
              className="link-white"
              to={`/programmes/${urlPrettify(programmeName)}/${props.match.params.progcode.slice(0, -8)}/${playlistDate}`}
            >
              Playlists
            </Link>
            <span className="slash">/</span>
            <span>{formatDate(playlistDate)}</span>
          </h2>
          <div className="helper-container">
            {renderLeftColumn()}
            <div className="right-column">
              {playlist.length > 0 && <ul className="playlist">
                <li className="list-header">
                  <span>Artist</span>
                  <span>Track</span>
                  <span>Album</span>
                  <span>Catalogue</span>
                </li>
                {playlist.map((item, index) => (
                  <li key={item.id} className="list-row">
                    <div className="list-cell list-cell-primary">
                      {item.artist && <Link
                        className="link link-white"
                        to={{
                          pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`,
                          data: item.artistid
                        }}>
                        {item.artist.name}
                      </Link>
                      }
                    </div>
                    <div className="list-cell">
                      <span className="label">Track</span>
                      <span className="value">{item.track?.tracktitle}</span>
                    </div>
                    <div className="list-cell">
                      <span className="label">Album</span>
                      <span className="value">{item.album?.title}</span>
                    </div>
                    <div className={item.album?.catalogue ? 'list-cell' : 'list-cell-hide'}>
                      <span className="label">Catalogue</span>
                      <span className="value">{item.album?.catalogue}</span>
                    </div>
                  </li>
                ))}
              </ul>}
              {/*{morePlaylists && morePlaylists.length > 2 && <div className="more-playlists-sidebar">
                  <span className="heading">Also played in {playlistDate?.split('-')[0]}</span>
                  <ul className="list">
                    {morePlaylists.map((item, index) => {
                      const { programme } = props.match.params;
                      return (
                        <li key={index} className="item">
                          <Link 
                            className="link link-white"
                            to={{ pathname: `/playlists/${programme}/${item.date}/${item.progcode}` }}
                          >
                            {item.artist.wwwimage ? 
                              <img className="img" src={`${process.env.REACT_APP_API_BASE_URL}/public/images/artists/covers/original/${item.artist.wwwimage}`} alt={item.artist.name} /> :
                              <div className="placeholder-img">
                                <IoMdMusicalNote className="placeholder-icon" size={60} />
                              </div>
                            } 
                            <div className="description">
                              <span className="track-name">{`${item.tracknumber}. ${item.track.tracktitle}`}</span>
                              <span className="artist-name">{item.artist.name}</span>
                            </div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              } */}
            </div>
          </div>
        </div>
      </section>
      <div className="print">
        {playlist.length > 0 && <table className="playlist">
          <thead className="list-header">
            <tr className="title">
              <td colSpan={4}><h1>{programmeName}</h1>
              </td>
            </tr>
            <tr className="radio">
              <td></td>
              <td colSpan={3}>
                <h3>{radioStation}</h3>
              </td>
            </tr>
            <tr className="date">
              <td></td>
              <td colSpan={3}>
                <h3>{formatDateLong(playlistDate)}</h3>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>Artist</td>
              <td>Track</td>
              <td>Album</td>
            </tr>
          </thead>
          <tbody>
            {playlist.map((item, index) => {
              return [
                <tr key={item.id} className="list-row">
                  <td className="list-cell">
                    <span className="value">{index + 1}</span>
                  </td>
                  <td className="list-cell list-cell-primary">
                    {item.artist &&
                      item.artist.name
                    }
                  </td>
                  <td className="list-cell">
                    <span className="value">{item.track?.tracktitle}</span>
                  </td>
                  <td className="list-cell">
                    <span className="value">{item.album?.title}</span>
                  </td>
                </tr>,
                <tr key={index} className="list-row"><td></td><td></td><td></td><td></td></tr>
              ]
            })}
          </tbody>
        </table>}
      </div>
    </div>
  );
};

export default PlaylistProgramme;