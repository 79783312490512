import React from 'react';
import { Link } from 'react-router-dom';

import SocialLinks from '../SocialLinks';

const Footer = () => {
  return (
    <div className="footer">
      <div className="wrapper">
        <div className="col col-1">
          <div className="logo">
            <img src="/logo.svg" alt="logo" />
          </div>
          <div className="3b-badge-mobile">
            <a className="link" href="https://www.3bweb.com/" target="_blank" rel="noopener noreferrer">
              <img src={require('../../images/3b_badge.png')} alt="3b-badge" />
            </a>
          </div>
        </div>
        <div className="col col-2">
          <SocialLinks size={28} />
          <div className="3b-badge">
            <a className="link" href="https://www.3bweb.com/" target="_blank" rel="noopener noreferrer">
              <img src={require('../../images/3b_badge.png')} alt="3b-badge" />
            </a>
          </div>
        </div>
        <div className="break"></div>
        <div className="col col-3">
          <ul className="nav-list">
            <li className="item">
              <Link className="link" to="/">Home</Link>
            </li>
            <li className="item">
              <Link className="link" to="/artists/a/1">Artists</Link>
            </li>
            <li className="item">
              <Link className="link" to="/search-playlists">Tracks & Artists Played</Link>
            </li>
            <li className="item">
              <Link className="link" to="/programmes">Programmes</Link>
            </li>
          </ul>
        </div>
        <div className="col col-4">
          <ul className="nav-list">
            <li className="item">
              <Link className="link" to="/terms-of-use">Terms of Use</Link>
            </li>
            <li className="item">
              <a className="link" href="https://www.bobharris.org/" target="_blank" rel="noopener noreferrer">Contact Bob</a>
            </li>
          </ul>
        </div>
        <div className="col col-5 3b-badge-mobile-small">
          <a className="link" href="https://www.3bweb.com/" target="_blank" rel="noopener noreferrer">
            <img src={require('../../images/3b_badge.png')} alt="3b-badge" />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;