import React, { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IoMdClose, IoIosArrowDown, IoIosSearch } from 'react-icons/io';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import MetaTags from '../components/MetaTags';
import CustomImage from '../components/CustomImage';
import { Context as PlaylistsContext } from '../context/PlaylistsContext';
import { Context as ProgrammesContext } from '../context/ProgrammesContext';
import { urlPrettify } from '../helpers/urlPrettify';
import { formatDate } from '../helpers/formatDate';
import { formatProgrammeName } from '../helpers/formatProgrammeName';
const qs = require('qs');

const PlaylistsSearch = (props) => {
  const { 
    state: { playlists, playlistsCount, mostPlayed, mostPlayedCount }, 
    searchPlaylists, 
    resetPlaylistsState, 
    getMostPlayed,
    resetPlaylistsSearchState
  } = useContext(PlaylistsContext);
  const { state: { programmes }, getProgrammes } = useContext(ProgrammesContext);
  const [pageSearch, setPageSearch] = useState(1);
  const [pageMostPlayed, setPageMostPlayed] = useState(1);
  const [search, setSearch] = useState('');
  const [searchSubmitted, setSearchSubmitted] = useState(false);
  const [activeSearchCategory, setActiveSearchCategory] = useState('all');
  const [activeTopCategory, setActiveTopCategory] = useState('artists');
  const [numResMostPlayed, setNumResMostPlayed] = useState(10);
  const [programmeMostPlayed, setProgrammeMostPlayed] = useState('all');
  const [startDate, setStartDate] = useState(new Date('1970-01-01'));
  const [endDate, setEndDate] = useState(new Date());
  const [showMostPlayed, setShowMostPlayed] = useState(true);
  const [day, setDay] = useState('any');
  const [month, setMonth] = useState('any');
  const [year, setYear] = useState('any');
  const history = useHistory();
  const location = useLocation();
  // Generate days, months and years avaliable to filter
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = Array.from({ length: 12 }, (_, i) => i + 1);
  const monthsNames = moment.monthsShort();
  const years = Array(endDate.getFullYear() - new Date('1970-01-01').getFullYear() + 1).fill().map((_, idx) => new Date('1970-01-01').getFullYear() + idx).reverse();
  useEffect(() => {
    let initialActiveCategory = 'artists';
    let numberResults = 10;
    let initialShortcode = 'all';
    let initialStateDate = new Date('1970-01-01');
    let initialEndDate = new Date();
    let initialPageSearch = 1;
    let initialPageMostPlayed = 1;
    let initialSearch = '';
    let initialSearchCategory = 'all';
    // Start initial values for day, year and month
    let initialDay = 'any';
    let initialMonth = 'any';
    let initialYear = 'any';

    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    if (paramsObj.type) {
      initialActiveCategory = paramsObj.type;
      setActiveTopCategory(initialActiveCategory);
    }
    if (paramsObj.top) {
      numberResults = paramsObj.top;
      setNumResMostPlayed(numberResults);
    }
    if (paramsObj.startDate) {
      initialStateDate = new Date(paramsObj.startDate);
      setStartDate(initialStateDate);
    }
     if (paramsObj.endDate) {
      initialEndDate = new Date(paramsObj.endDate);
      setEndDate(initialEndDate);
    }
    if (paramsObj.pageMostPlayed) {
      initialPageMostPlayed = paramsObj.pageMostPlayed;
      setPageMostPlayed(initialPageMostPlayed);
    }
    if (paramsObj.query) {
      initialSearch = paramsObj.query;
      setSearch(initialSearch);
    }
    if (paramsObj.searchCategory) {
      initialSearchCategory = paramsObj.searchCategory;
      setActiveSearchCategory(initialSearchCategory);
    }
    if (paramsObj.pageSearch) {
      initialPageSearch = paramsObj.pageSearch;
      setPageSearch(initialPageSearch);
    }
    if (paramsObj.showMostPlayed == 'false') {
      setShowMostPlayed(false);
    }
    if (paramsObj.day){
      initialDay = paramsObj.day;
      setDay(initialDay);
    } 
    if (paramsObj.month) {
      initialMonth = paramsObj.month;
      setMonth(initialMonth);
    } 
    if (paramsObj.year) {
      initialYear = paramsObj.year;
      setYear(initialYear);
    } 

    searchPlaylists(initialSearch, initialSearchCategory, initialDay, initialMonth, initialYear, initialPageSearch);

    getProgrammes()
      .then(response => {
        if (paramsObj.shortcode) {
          initialShortcode = paramsObj.shortcode;
          setProgrammeMostPlayed(initialShortcode);
        }
        getMostPlayed(
          initialActiveCategory, 
          numberResults, 
          [initialStateDate.toISOString().split('T')[0], initialEndDate.toISOString().split('T')[0]], 
          initialShortcode, 
          initialPageMostPlayed, 
          10
        );
      });

    return () => {
      resetPlaylistsState();
    };
  }, []);

  const onSearchChanged = (text) => {
    setSearch(text);
    if (!text) {
      resetPlaylistsSearchState();
      setSearchSubmitted(false);
      searchPlaylists('', 'all', day, month, year, 1);
    }
  };

  const onSearchSubmit = () => {
    if (search) {
      const params = location.search.slice(1);
      const paramsObj = qs.parse(params);
      paramsObj.query = search;
      const paramsStr = qs.stringify(paramsObj);
      history.push(`/search-playlists/search?${paramsStr}`);

      setPageSearch(1);
      if (search) {
        searchPlaylists(search, activeSearchCategory, day, month, year, 1)
          .then(response => setSearchSubmitted(true));
      } else {
        resetPlaylistsSearchState();
      }
    }
  };

  const onSearchReset = () => {
    searchPlaylists('', 'all', day, month, year, 1);
    setActiveSearchCategory('all');
    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    paramsObj.query = undefined;
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);
    setSearch('');
    setSearchSubmitted(false);
    setPageSearch(1);    
    resetPlaylistsSearchState();         
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearchSubmit();
    }
  };

  const pageClickHandlerSearch = data => {
    if (pageSearch === `${data.selected} + 1`) {
      return;
    }

    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    if (data.selected + 1 !== 1) {
      paramsObj.pageSearch = data.selected + 1;
    } else {
      paramsObj.pageSearch = undefined;
    }
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);

    setPageSearch(data.selected + 1);
    
    searchPlaylists(search, activeSearchCategory, day, month, year, data.selected + 1);
    window.scrollTo(0, 0);
  };

  const pageClickHandlerMostPlayed = data => {
    if (pageMostPlayed === `${data.selected} + 1`) {
      return;
    }

    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    if (data.selected + 1 !== 1) {
      paramsObj.pageMostPlayed = data.selected + 1;
    } else {
      paramsObj.pageMostPlayed = undefined;
    }
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);

    setPageMostPlayed(data.selected + 1);

    getMostPlayed(
      activeTopCategory, 
      numResMostPlayed, 
      [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]], 
      programmeMostPlayed, 
      data.selected + 1, 
      10
    );
    window.scrollTo(0, 0);
  };

  const numberOfResultsSetHandler = (e) => {
    const params = location.search.slice(1);  
    const paramsObj = qs.parse(params);
    if (e.target.value !== '10') {
      paramsObj.top = e.target.value;
    } else {
      paramsObj.top = undefined;
    }
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);
    setPageMostPlayed(1);
    setNumResMostPlayed(e.target.value);
    getMostPlayed(
      activeTopCategory, 
      e.target.value, 
      [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]], 
      programmeMostPlayed, 
      1, 
      10
    );
  };

  const programmeSetHandler = (e) => {
    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    const programme = programmes.filter(item => item.shortcode === e.target.value);
    if (e.target.value !== 'all') {
      paramsObj.programme = urlPrettify(programme[0].Programme);
      paramsObj.shortcode = programme[0].shortcode;
    } else {
      paramsObj.programme = undefined;
      paramsObj.shortcode = undefined;
    }
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);
    setPageMostPlayed(1);
    setProgrammeMostPlayed(e.target.value);
    
    getMostPlayed(
      activeTopCategory, 
      numResMostPlayed, 
      [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]], 
      e.target.value, 
      1, 
      10
    );
  };

  const setDateRangeHandler = (type, value) => {
    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);

    if (type === 0) {
      paramsObj.startDate = value.toISOString().split('T')[0];
      setStartDate(value);
    } else {
      paramsObj.endDate = value.toISOString().split('T')[0];
      setEndDate(value);
    }

    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);

    const range = [
      paramsObj.startDate ? paramsObj.startDate : startDate.toISOString().split('T')[0], 
      paramsObj.endDate ? paramsObj.endDate : endDate.toISOString().split('T')[0]
    ];
    getMostPlayed(
      activeTopCategory, 
      numResMostPlayed, 
      range, 
      programmeMostPlayed, 
      pageMostPlayed, 
      10
    );
  };  
  const filterByDayHandler = (e) => {
    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    paramsObj.day = e.target.value;
    let month = 'any';
    let year = 'any';
    if (paramsObj.hasOwnProperty('month')){
      month = paramsObj.month;
    }
    if (paramsObj.hasOwnProperty('year')) {
      year = paramsObj.year;
    }
    paramsObj.month = month;
    paramsObj.year = year;
    setDay(e.target.value);
    setMonth(month);
    setYear(year);
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);
    searchPlaylists(search, paramsObj.searchCategory, e.target.value, month, year, 1);
    setSearchSubmitted(true);
  }
  const filterByMonthHandler = (e) => {
    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    paramsObj.month = e.target.value;
    let day = 'any';
    let year = 'any';
    if (paramsObj.hasOwnProperty('day')) {
      day = paramsObj.day;
    }
    if (paramsObj.hasOwnProperty('year')) {
      year = paramsObj.year;
    }
    paramsObj.day = day;
    paramsObj.year = year;
    setDay(day);
    setMonth(e.target.value);
    setYear(year);
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);
    searchPlaylists(search, paramsObj.searchCategory, day, e.target.value, year, 1);
    setSearchSubmitted(true);
  }
  const filterByYearHandler = (e) => {
    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    paramsObj.year = e.target.value;
    let month = 'any';
    let day = 'any';
    if (paramsObj.hasOwnProperty('month')) {
      month = paramsObj.month;
    }
    if (paramsObj.hasOwnProperty('day')) {
      day = paramsObj.day;
    }
    paramsObj.month = month;
    paramsObj.day = day;
    setDay(day);
    setMonth(month);
    setYear(e.target.value);
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);
    searchPlaylists(search, paramsObj.searchCategory, day, month, e.target.value, 1);
    setSearchSubmitted(true);
  }

  const searchCategoryClickHandler = (category) => {
    const params = location.search.slice(1);  
    const paramsObj = qs.parse(params);
    paramsObj.searchCategory = category;
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);
    setActiveSearchCategory(category);
    searchPlaylists(search, category, day, month, year, 1)
  };

  const topCategoryClickHandler = (category) => {  
    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    paramsObj.type = category;
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);
    setPageMostPlayed(1);
    setActiveTopCategory(category);
    getMostPlayed(
      category, 
      numResMostPlayed, 
      [startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]], 
      programmeMostPlayed, 
      pageMostPlayed, 
      10
    );
  };

  const onTabChange = (state) => {
    setShowMostPlayed(state);
    const params = location.search.slice(1);
    const paramsObj = qs.parse(params);
    if (!state) {
      paramsObj.showMostPlayed = false;
    } else {
      paramsObj.showMostPlayed = undefined;
    }
    const paramsStr = qs.stringify(paramsObj);
    history.push(`/search-playlists/search?${paramsStr}`);
  };

  const renderTopCategoryHeader = (category) => {
    if (category === 'artists') {
      return (
        <li className="list-header">
          <span className="col-10">Pos</span>
          <span className="col-70">Artist</span>
          <span className="col-20">Plays</span>
        </li>
      );
    }
    if (category === 'albums') {
      return (
        <li className="list-header">
          <span className="col-10">Pos</span>
          <span className="col-50">Artist</span>
          <span className="col-30">Album</span>
          <span className="col-10">Plays</span>
        </li>
      );
    }
    if (category === 'tracks') {
      return (
        <li className="list-header">
          <span className="col-10">Pos</span>
          <span className="col-50">Artist</span>
          <span className="col-20">Album</span>
          <span className="col-20">Track</span>
          <span className="col-10">Plays</span>
        </li>
      );
    }
  };

  const renderTopCategoryCell = (item, index) => {
    const position = index + 1 + (pageMostPlayed - 1) * 10;
    let smallImageName;
    if (item.artist && item.artist.wwwimage) {
      const imageNameParts = item.artist.wwwimage.split('.');
      const modifiedExtension = `-small.${imageNameParts[imageNameParts.length - 1]}`;
      imageNameParts.splice(-1, 1);
      const joinParts = imageNameParts.join('.');
      smallImageName = `${joinParts}${modifiedExtension}`;
    }
    if (item.artist && item.artistid) {
      return (
        <li key={index} className="item">
          <div className="col-10 list-cell list-cell-desktop">{position}</div>
          <div className="artist col-70 list-cell"> 
            <Link 
                className="image-link"
              to={{ pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`, data: item.artist}}
              > 
              <CustomImage 
                type={item.artist.wwwimage ? '' : 'local' }
                img={item.artist.wwwimage ? `artists/covers/small/${smallImageName}` : require('../images/default_image.jpg')}
                classNameWrapper="img-container"
                classNameImg="img" 
                alt={item.artist.name} />
            </Link> 
            <Link 
              className="link link-white"
              to={{ pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`, data: item.artist}}
            >
              {item.artist.name}
            </Link>
          </div>
          <div className="list-cell list-cell-mobile">
            <span className="label">Pos</span> 
            <span className="value">{position}</span> 
          </div>
          <div className="col-20 list-cell">
            <span className="label">Plays</span> 
            <span className="value">{item.count}</span> 
          </div>
        </li>
      );
    }
    if (item.artist && item.albumid) {
      return (
        <li key={index} className="item">
          <div className="col-10 list-cell list-cell-desktop">{position}</div>
          <div className="artist col-50 list-cell"> 
            <Link 
              className="image-link"
              to={{ pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`, data: item.artist}}
            >
              <CustomImage 
                type={item.artist.wwwimage ? '' : 'local' }
                img={item.artist.wwwimage ? `artists/covers/small/${smallImageName}` : require('../images/default_image.jpg')}
                classNameWrapper="img-container"
                classNameImg="img" 
                alt={item.artist.name} />
            </Link> 
            <Link 
              className="link link-white"
              to={{ pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`, data: item.artist}}
            >
              {item.artist.name}
            </Link>
          </div>
          <div className="list-cell list-cell-mobile">
            <span className="label">Pos</span> 
            <span className="value">{position}</span> 
          </div>
          <div className="col-30 list-cell">
            <span className="label">Album</span> 
            <span className="value">{item.album.title}</span>
          </div>
          <div className="col-10 list-cell">
            <span className="label">Plays</span> 
            <span className="value">{item.count}</span>
          </div>
        </li>
      );
    }
    if (item.artist && item.trackid) {
      return (
        <li key={index} className="item">
          <div className="col-10 list-cell list-cell-desktop">{position}</div>
          <div className="artist col-50 list-cell"> 
            <Link 
              className="image-link"
              to={{ pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`, data: item.artist}}
            >
              <CustomImage 
                type={item.artist.wwwimage ? '' : 'local' }
                img={item.artist.wwwimage ? `artists/covers/small/${smallImageName}` : require('../images/default_image.jpg')}
                classNameWrapper="img-container"
                classNameImg="img" 
                alt={item.artist.name} />
            </Link> 
            <Link 
              className="link link-white"
              to={{ pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`, data: item.artist}}
            >
              {item.artist.name}
            </Link>
          </div>
          <div className="list-cell list-cell-mobile">
            <span className="label">Pos</span> 
            <span className="value">{position}</span>
          </div>
          <div className="col-20 list-cell">
            <span className="label">Album</span> 
            <span className="value">{item.album?.title}</span>
          </div>
          <div className="col-20 list-cell">
            <span className="label">Track</span> 
            <span className="value">{item.track.tracktitle}</span>
          </div>
          <div className="col-10 list-cell">
            <span className="label">Plays</span> 
            <span className="value">{item.count}</span>
          </div>
        </li>
      );
    }
  };

  const renderSearchResults = (item) => {
    let match;
    if (item.match === 'artist') {
      match = item.artist.artistName;
    }
    if (item.match === 'album') {
      match = item.album.albumTitle;
    }
    if (item.match === 'track') {
      match = item.track.trackTitle;
    }
    if(item.match === 'show') {
      if (item.show.showTitle){
      match = item.show.showTitle;
      }else{
        match = 'Untitled playlist';
      }
    }
    return ( 
      match && match.replace(new RegExp(search, 'gi'), (searchTerm) => `<span style='color: #ffbb00'>${searchTerm}</span>`)
    );
  };

  const generatePath = (item) => {
      let path;
      console.log(item);
      if (item.match === 'artist') {
          path = `/artist/${urlPrettify(item.artist.artistName).replace('/', '')}/${item.artist.artistId}`;
      }
      if (item.match === 'show') {
          let programme = programmes.filter(p => p.shortcode === item.playlist.progletter)[0]?.Programme;
          path = `/${urlPrettify(programme)}/playlists/${item.playlist.date}/${item.playlist.progcode}`;
      }
      return path
  }

	return (
		<div className="content-page playlists-search">
    <MetaTags title="Search | Bob Harris Archive" description="Search | Bob Harris Archive" />
      <section className="search-section">
        <div className="section-wrapper section-wrapper-full-height section-wrapper-green">
          <div className="tabs">
            <div onClick={() => onTabChange(true)} className={showMostPlayed ? 'tab active-tab' : 'tab'}>
              <h2 className="heading-white">
                <span>Most Played</span>
                <IoIosArrowDown className="icon" size={22} />
              </h2>
            </div>
            <div onClick={() => onTabChange(false)} className={!showMostPlayed ? 'tab active-tab' : 'tab'}>
              <h2 className="heading-white">
                <span>Search Playlists</span>
                <IoIosSearch className="icon" size={22} />
              </h2>
            </div>
          </div>
          <div className="row">
            <div className={showMostPlayed ? 'top-column' : 'top-column top-column-mobile'}>
              <h2 className="section-heading heading-white">
                <span>Most Played</span>
              </h2>
              <ul className="categories-list">
                <li className={activeTopCategory === 'artists' ? 'item active ' : 'item'} onClick={() => topCategoryClickHandler('artists')}>
                  Artists
                </li>
                <li className={activeTopCategory === 'albums' ? 'item active ' : 'item'} onClick={() => topCategoryClickHandler('albums')}>
                  Albums
                </li>
                <li className={activeTopCategory === 'tracks' ? 'item active ' : 'item'} onClick={() => topCategoryClickHandler('tracks')}>
                  Tracks
                </li>
              </ul>
              <div className="most-played-form">
                <form className="form">
                  <div className="input-wrapper">
                    <select className="filter" onChange={numberOfResultsSetHandler} name="number" value={numResMostPlayed}>
                      <option value="10">Top 10</option>
                      <option value="20">Top 20</option>
                      <option value="50">Top 50</option>
                    </select>                     
                    <select className="filter programme-filter" onChange={programmeSetHandler} name="programme" value={programmeMostPlayed}>
                      <option value='all'>All Programmes</option>
                      {programmes.map(item => (
                        <option key={item.ID} value={item.shortcode}>{item.Programme}</option>
                      ))}
                    </select>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={startDate}
                      onChange={val => setDateRangeHandler(0, val)}
                      popperClassName="date-input" 
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={10}
                      adjustDateOnChange
                    />
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      selected={endDate}
                      onChange={val => setDateRangeHandler(1, val)}
                      popperClassName="date-input"
                      showMonthDropdown
                      showYearDropdown
                      yearDropdownItemNumber={10}
                      adjustDateOnChange
                    />
                  </div>  
                </form>
              </div>
              <ul className="most-played-list">            
                {renderTopCategoryHeader(activeTopCategory)}
                {mostPlayed.length > 0 && mostPlayed.map((item, index) => (
                  renderTopCategoryCell(item, index)
                ))}
              </ul>
              {mostPlayedCount > 1 &&
                <ReactPaginate
                  pageCount={mostPlayedCount}
                  previousLabel={false}
                  nextLabel={false}
                  onPageChange={pageClickHandlerMostPlayed}
                  containerClassName={'pagination-light'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  forcePage={pageMostPlayed - 1}
                />
              }
            </div>
            <div className={!showMostPlayed ? 'search-column' : 'search-column search-column-mobile'}>
              <h2 className="section-heading heading-white">
                <span>Search Playlists</span>
              </h2>
              <div className="input-group">
                <input
                  type="text" 
                  onChange={event => onSearchChanged(event.target.value)} 
                  onKeyDown={handleKeyDown}
                  className="input" 
                  placeholder="Artists, albums or tracks"
                  value={search}
                />
                {search && <IoMdClose onClick={onSearchReset} className="reset-icon" size={25} />}
                <button type="button" className="submit-button" onClick={() => onSearchSubmit()}>Search</button>   
              </div>
              <div className="date-filter">
                <p>Filter by date:</p>
                <div className="filters">
                  <div>
                  <label htmlFor="day">Day</label>
                  <select className="filter" onChange={filterByDayHandler} name="day" value={day}>
                    <option value="any">Any</option>
                    {days.map((day) => <option value={day} key={day}>{day}</option>)}
                  </select>
                  </div>
                <div>
                  <label htmlFor="month">Month</label>
                  <select className="filter" onChange={filterByMonthHandler} name="month" value={month}>
                    <option value="any">Any</option>
                    {months.map((month) => <option value={month} key={month}>{monthsNames[month - 1]}</option>)}
                  </select>
                </div>
                <div>
                  <label htmlFor="year">Year</label>
                  <select className="filter" onChange={filterByYearHandler} name="year" value={year}>
                    <option value="any">Any</option>
                    {years.map((year) => <option value={year} key={year}>{year}</option>)}
                  </select>
                </div>
                </div>
              </div>
              <ul className="categories-list">
                <li className={activeSearchCategory === 'all' ? 'item active ' : 'item'} onClick={() => searchCategoryClickHandler('all')}>
                  All
                </li>
                <li className={activeSearchCategory === 'artists' ? 'item active ' : 'item'} onClick={() => searchCategoryClickHandler('artists')}>
                  Artists
                </li>
                <li className={activeSearchCategory === 'albums' ? 'item active ' : 'item'} onClick={() => searchCategoryClickHandler('albums')}>
                  Albums
                </li>
                <li className={activeSearchCategory === 'tracks' ? 'item active ' : 'item'} onClick={() => searchCategoryClickHandler('tracks')}>
                  Tracks
                </li>
                <li className={activeSearchCategory === 'shows' ? 'item active ' : 'item'} onClick={() => searchCategoryClickHandler('shows')}>
                  Shows
                </li>
              </ul>
              {searchSubmitted && playlists.length === 0  && <p className="no-results">No results found</p>}
              {playlists.length > 0 && <ul className="search-list">
                  {playlists.map((item, index) => {
                    let programme;
                    if (item.playlist) {
                      programme = programmes.filter(p => p.shortcode === item.playlist.progletter)[0]?.Programme;
                    }
                    return (
                        <li className="item" key={index}>
                            <div className="link-container">
                                {programme && <Link
                                    className="link link-white"
                                    to={{
                                        pathname: `/${urlPrettify(programme)}/playlists/${item.playlist.date}/${item.playlist.progcode}`,
                                        data: {
                                            progcode: item.playlist.progcode,
                                            programmeName: programme
                                        }
                                    }}>
                                    {formatDate(item.playlist.date)}
                                </Link>}
                            </div>
                        <div className="match-container">
                          <div className="match">
                            {(item.match === 'artist' || item.match === 'show') ?
                              <Link className="link link-white" to={{ pathname: generatePath(item), data: item.match == 'artist' ? item.album : item.match == 'show' && item.show }}>
                                <span dangerouslySetInnerHTML={{ __html: renderSearchResults(item) }}></span>
                              </Link>
                              :
                              <span dangerouslySetInnerHTML={{ __html: renderSearchResults(item) }}></span>
                            }
                            {activeSearchCategory === 'all' &&
                              <span className="match-type">
                                {`${item.match.charAt(0).toUpperCase()}${item.match.slice(1)}`}
                              </span>
                            }
                          </div>
                        </div>
                        </li>
                    );
                  })}
                </ul>
              }
              {playlistsCount > 1 &&
                <ReactPaginate
                  pageCount={playlistsCount}
                  previousLabel={'previous'}
                  nextLabel={'next'}
                  pageRangeDisplayed={4}
                  onPageChange={pageClickHandlerSearch}
                  containerClassName={'pagination-light'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                  forcePage={pageSearch - 1}
                />
              }
            </div>
          </div>
        </div>
      </section>
    </div>
	);
};

export default PlaylistsSearch;