import "core-js/stable";
import "regenerator-runtime/runtime";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import 'normalize.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider as AdminProvider } from './context/AdminContext';
import { Provider as AuthProvider } from './context/AuthContext';
import { Provider as HomepageProvider } from './context/HomepageContext';
import { Provider as ArtistsProvider } from './context/ArtistsContext';
import { Provider as ProgrammesProvider } from './context/ProgrammesContext';
import { Provider as SessionsProvider } from './context/SessionsContext';
import { Provider as PlaylistsProvider } from './context/PlaylistsContext';
import { Provider as TracksProvider } from './context/TracksContext';
import { Provider as AlbumsProvider } from './context/AlbumsContext';
import { Provider as GalleryProvider } from './context/GalleryContext';
import ScrollToTop from './components/ScrollToTop';

const oktaConfig = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirectUri: `${window.location.origin}/implicit/callback`,
  pkce: true
};

ReactDOM.render(
  <BrowserRouter>
    <Security {...oktaConfig}>
      <GalleryProvider>
        <AlbumsProvider>
          <TracksProvider>
            <PlaylistsProvider>
              <SessionsProvider>
                <ProgrammesProvider>
                  <ArtistsProvider>
                    <HomepageProvider>
                      <AuthProvider> 
                        <AdminProvider>
                          <ScrollToTop />
                          <App />
                        </AdminProvider>
                      </AuthProvider>
                    </HomepageProvider>
                  </ArtistsProvider>
                </ProgrammesProvider>
              </SessionsProvider>
            </PlaylistsProvider>
          </TracksProvider>
        </AlbumsProvider>
      </GalleryProvider>
    </Security>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (module.hot) module.hot.accept();