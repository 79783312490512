import React, { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { IoMdClose } from 'react-icons/io';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CustomImage from '../components/CustomImage';
import MetaTags from '../components/MetaTags';
import { Context as ArtistsContext } from '../context/ArtistsContext';
import { Context as PlaylistsContext } from '../context/PlaylistsContext';
import { urlPrettify } from '../helpers/urlPrettify';

const ArtistsList = (props) => {
  const {
    state: { artists, artistsCount, localArtistsReset }, 
    getArtists, 
    resetLocalArtistsState,
    resetArtistsState
  } = useContext(ArtistsContext);
  const { getMostPlayed, resetPlaylistsState } = useContext(PlaylistsContext);
  const [activeLetter, setActiveLetter] = useState('A');
  const [page, setPage] = useState(null);
  const [search, setSearch] = useState('');
  const [topArtists, setTopArtists] = useState([]);
  const [startDate, setStartDate] = useState(new Date('1998-01-01'));
  const [endDate, setEndDate] = useState(new Date());
  const history = useHistory();
  const location = useLocation();
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    pauseOnFocus: true,
    draggable: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  useEffect(() => {
    let pageInt;
    if (props.location.search) {
      pageInt = parseInt(props.location.search.split('/')[1]);
    } else {
      pageInt = props.match.params.page;
    }
    const letter = props.match.params.letter;
    const search = decodeURI(props.location.search.split('/')[0].substr(1));
    setPage(pageInt);
    setActiveLetter(letter.toUpperCase());
    if (search) {
      setSearch(search);
      getArtists(null, search, pageInt);
    } else {   
      getArtists(letter, null, pageInt);
    }

    getMostPlayed('artists', 20, [startDate, endDate], 'all', 1, 20)
      .then(response => {
        setTopArtists(response);
        console.log(response)
      });

    return () => {
      resetArtistsState();
      resetPlaylistsState();
    };
  }, []);

  useEffect(() => {
    if (localArtistsReset) {
      setPage(1);
      setActiveLetter('A');
      getArtists('A', null, 1);
      setSearch('');
      resetLocalArtistsState(false);
    }
  }, [localArtistsReset]);

  const pageClickHandler = data => {
    if (page === `${data.selected} + 1`) {
      return;
    }
    setPage(data.selected + 1);

    if (search) {
      history.push(`/artists/${activeLetter.toLowerCase()}/search?${search}/${data.selected + 1}`);
      window.scrollTo(0, 0);
      getArtists(null, search, data.selected + 1);
    } else {
      history.push(`/artists/${activeLetter.toLowerCase()}/${data.selected + 1}`);
      window.scrollTo(0, 0);
      getArtists(activeLetter, null, data.selected + 1);
    }
  };

  const setActiveLetterHandler = (letter) => {
    setActiveLetter(letter);
    setPage(1);
    setSearch('');
    history.push(`/artists/${letter.toLowerCase()}/1`)
    getArtists(letter, null, 1);
  };

  const onSearchChanged = (text) => {
    setSearch(text);
    if (text) {
     getArtists(null, text, 1)
      .then(response => { 
        history.push(`/artists/${activeLetter.toLowerCase()}/search?${text}/1`);
        setPage(1);
      });
    } else {
      getArtists(activeLetter, '', 1)
      .then(response => { 
        history.push(`/artists/${activeLetter.toLowerCase()}/1`);
        setPage(1);
      });
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
  };

	return (
    <div className="content-page artists-list">
      <MetaTags title="Artists | Bob Harris Archive" description="Artists | Bob Harris Archive" />
  		<section className="list-section">
        <div className="section-wrapper section-wrapper-full-height section-wrapper-green">
          <h2 className="section-heading heading-white">
            <span>Artists </span>
          </h2>
          <div className="search-container">
            <ul className="letters-list">
              {letters.map((item, index) => (
                <li className={activeLetter === item ? 'active item' : 'item'} onClick={() => setActiveLetterHandler(item)} key={item}>
                  {item}
                </li>
              ))}
            </ul>
            <form onSubmit={submitHandler}>
              <div className="input-group">
                <input
                  type="text" 
                  onChange={event => onSearchChanged(event.target.value)} 
                  className="input" 
                  placeholder="Search artists..."
                  value={search}
                />
                {search && <IoMdClose 
                    onClick={() => {
                      setSearch('');
                      setPage(1);
                      history.push(`/artists/${activeLetter.toLowerCase()}/1`);
                      getArtists(activeLetter, null, 1);                            
                    }} 
                    className="reset-icon" size={25} 
                  />
                }
              </div>
            </form>
          </div>
          {search && artists.length === 0 && <p className="no-results">No artists found</p>}
          <ul className="list">
            {artists.length > 0 && artists.map(item => {
              const nameParam = item.name.split('/').join('-');
              let smallImageName
              if (item.wwwimage) {
                const imageNameParts = item.wwwimage.split('.');
                const modifiedExtension = `-small.${imageNameParts[imageNameParts.length - 1]}`;
                imageNameParts.splice(-1, 1);
                const joinParts = imageNameParts.join('.');
                smallImageName = `${joinParts}${modifiedExtension}`;
              }
              return (
                <li className="item" key={item.artistid}>
                  <Link 
                    className="link"
                    to={{ pathname: `/artist/${urlPrettify(nameParam)}/${item.artistid}`, data: item}}
                  > 
                    {item.wwwimage &&
                      <CustomImage 
                        img={item.wwwimage ? `artists/covers/small/${smallImageName}` : require('../images/default_image.jpg')}
                        img={`artists/covers/small/${smallImageName}`}
                        classNameWrapper='img-container'
                        classNameImg='img'
                        alt={item.name} />
                    }
                    <span className="artist">{item.name}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
          {artistsCount > 1 && <ReactPaginate 
            pageCount={artistsCount}
            previousLabel={'previous'}
            nextLabel={'next'}
            pageRangeDisplayed={4}
            onPageChange={pageClickHandler}
            containerClassName={'pagination-light'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            disableInitialCallback={true}
            forcePage={page - 1}
          />}
        </div>
      </section>
      <section className="most-played-artists">
        <div className="section-wrapper section-wrapper-green-dark">
          <h2 className="section-heading">
            <span>Most Played Artists</span>
          </h2>
          <Slider {...settings}>
            {topArtists.map((item, index) => {
              let smallImageName;
              if (item.artist.wwwimage) {
                const imageNameParts = item.artist.wwwimage.split('.');
                const modifiedExtension = `-small.${imageNameParts[imageNameParts.length - 1]}`;
                imageNameParts.splice(-1, 1);
                const joinParts = imageNameParts.join('.');
                smallImageName = `${joinParts}${modifiedExtension}`;
              }
              return (
                <div key={index} className="slider-item"> 
                  <Link 
                    className="link link-white"
                    to={{ pathname: `/artist/${urlPrettify(item.artist.name).replace('/', '')}/${item.artist.artistid}`, data: item}}
                  > 
                    {item.artist.wwwimage &&
                      <CustomImage 
                        img={item.artist.wwwimage ? `artists/covers/small/${smallImageName}` : require('../images/default_image.jpg')}
                        classNameWrapper="img-container"
                        classNameImg="img" 
                        alt={item.artist.name} />
                    }
                    <span className="artist">{item.artist.name}</span>
                  </Link>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>
    </div>
	);
};

export default ArtistsList;
