import React, { useEffect, useContext } from 'react';
import { BrowserRouter as Router, Link, Route, Switch, useLocation } from 'react-router-dom';
import { SecureRoute, LoginCallback, ImplicitCallback } from '@okta/okta-react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css'
// import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

import Header from './components/layout/Header';
import NavList from './components/layout/NavList';
import Footer from './components/layout/Footer';
import Home from './pages/Home';
import ArtistsList from './pages/ArtistsList';
import Artist from './pages/Artist';
import SessionsList from './pages/SessionsList';
import PlaylistsSearch from './pages/PlaylistsSearch';
import PlaylistsListProgramme from './pages/PlaylistsListProgramme';
import PlaylistProgramme from './pages/PlaylistProgramme';
import Programmes from './pages/Programmes';
import Gallery from './pages/Gallery';
import Terms from './pages/Terms';
import Login from './pages/Login';
import HomepageManager from './pages/dashboard/HomepageManager';
import ArtistsManager from './pages/dashboard/ArtistsManager';
import TracksManager from './pages/dashboard/TracksManager';
import AlbumsManager from './pages/dashboard/AlbumsManager';
import PlaylistsManager from './pages/dashboard/PlaylistsManager';
import SessionsManager from './pages/dashboard/SessionsManager';
import ProgrammesManager from './pages/dashboard/ProgrammesManager';
import { Context as HomepageContext } from './context/HomepageContext';
import PageNotFound from './pages/PageNotFound';
import './styles/scss/styles.scss';

const App = (props) => {
  const { state: { menuIsOpen } } = useContext(HomepageContext);
  const location = useLocation();
  const { pathname, search } = location;
  // Initialize react-ga only once to avoid mutiple <script></script> tags
  useEffect(() => {
    if (process.env.REACT_APP_GA) {
      ReactGA.initialize(process.env.REACT_APP_GA);
    }
  }, []);
  
  useEffect(() => {
    if (process.env.REACT_APP_GA) {
      ReactGA.pageview(window.location.pathname + window.location.search);
      // TagManager.initialize({ gtmId: process.env.REACT_APP_GTM });
    }
  }, [pathname, search]);

  useEffect(() => {
    const script = document.createElement('script');
  
    script.src = "https://s.skimresources.com/js/201976X1680648.skimlinks.js";
    script.async = true;
  
    document.body.appendChild(script);
  
    return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    <div className="site-wrapper">
      <ReactNotification />
      <Header />
      {menuIsOpen && 
        <div className="nav-mobile-container">
          <NavList class="nav-list-mobile" />
        </div>
      }
      <main className="main">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/artists/:letter/:search?/:page" component={ArtistsList} />
          <Route exact path="/artist/:artist/:id" component={Artist} />
          <Route exact path="/search-playlists/:search?" component={PlaylistsSearch} />
          <Route exact path="/programmes/:programme/:shortcode/:year" component={PlaylistsListProgramme} />
          <Route exact path="/:programme/playlists/:date/:progcode" component={PlaylistProgramme} />
          <Route exact path="/sessions/:programme/:shortcode/:year/:page" component={SessionsList} />
          <Route exact path="/programmes" component={Programmes} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/terms-of-use" component={Terms} />
          <Route exact path="/login" component={Login} />
          <SecureRoute exact path="/dashboard/homepage" component={HomepageManager} />
          <SecureRoute exact path="/dashboard/artists" component={ArtistsManager} />
          <SecureRoute exact path="/dashboard/tracks" component={TracksManager} />
          <SecureRoute exact path="/dashboard/albums" component={AlbumsManager} />
          <SecureRoute exact path="/dashboard/playlists" component={PlaylistsManager} />
          <SecureRoute exact path="/dashboard/session" component={SessionsManager} />
          <SecureRoute exact path="/dashboard/programmes" component={ProgrammesManager} />
          <Route exact path="/implicit/callback" component={LoginCallback} />
        {/* <Route exact path="/implicit/callback" component={ImplicitCallback}  ???/> */}
          <Route component={PageNotFound} />
        </Switch>
      </main>
      <Footer />
    </div>
  );
}

export default App;

