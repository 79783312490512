import React, { Fragment } from 'react';
import { AiFillFacebook, AiOutlineTwitter, AiFillYoutube, AiFillAmazonCircle } from 'react-icons/ai';
import { FaNapster, FaYandexInternational, FaBandcamp, FaSoundcloud } from 'react-icons/fa';
import { FiGlobe } from 'react-icons/fi';
import { SiYoutubetv, SiDeezer , SiApplemusic, SiSpotify, SiAmazon} from 'react-icons/si';
import ArtistSocialButtonsList from './ArtistSocialButtonsList'

const ArtistSocialLinks = ({ size, website, facebook, twitter, youtube , artistName}) => {
  if (!website && (!facebook || facebook === '-') && !twitter && !youtube) return <Fragment/>;

  return (
    <div className="artist-social-links">
      <h2 className="heading heading-white"><span>Artist Links :</span></h2>
      <ul className="list">
        {website && <li className="item">
          <a className="link" href={website} target="_blank" rel="noopener noreferrer">
            <FiGlobe size={size} className="icon" />
            <span className="icon-label">Website</span>
          </a>
        </li>
        }
        {facebook && facebook !== '-' && <li className="item">
          <a className="link" href={`https://www.facebook.com/${facebook}`} target="_blank" rel="noopener noreferrer">
            <AiFillFacebook size={size} className="icon" />
            <span className="icon-label">Facebook</span>
          </a>
        </li>
        }
        {twitter && <li className="item">
          <a className="link" href={`https://www.twitter.com/${twitter}`} target="_blank" rel="noopener noreferrer">
            <AiOutlineTwitter size={size} className="icon" />
            <span className="icon-label">Twitter</span>
          </a>
        </li>
        }
        {youtube && <li className="item">
          <a className="link" href={`https://www.youtube.com/watch?v=${youtube}`} target="_blank" rel="noopener noreferrer">
            <AiFillYoutube size={size} className="icon" />
            <span className="icon-label">Youtube</span>
          </a>
        </li>
        }
        <ArtistSocialButtonsList artistName={artistName}/>
      </ul>
    </div>
  );
};

export default ArtistSocialLinks;
