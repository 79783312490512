import createDataContext from './createDataContext';

import API from '../api/api';

const authReducer = (state, action) => {
  switch (action.type) {
    case 'update_username':
      return { ...state, username: action.payload };
    default: 
      return state;
  }
};

const getUsername = dispatch => username => {
  dispatch({ type: 'update_username', payload: username })
};

export const { Context, Provider } = createDataContext(
  authReducer,
  { getUsername },
  { username: null }
);