import React from 'react';

const ArtistSocialButton = ({ href, website, children }) => {
  return (
    <li className="item">
      <a className="link" href={href} target="_blank" rel="noopener noreferrer">
        {children}
        <span className="icon-label">{website}</span>
      </a>
    </li>
  );
}

export default ArtistSocialButton;
