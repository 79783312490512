import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import { MdCached } from 'react-icons/md';

import { addNotification } from '../../helpers/addNotification';
import { Context as AuthContext } from '../../context/AuthContext';
import { Context as AdminContext } from '../../context/AdminContext';

const DashboardSidebar = (props, { tab }) => {
  const { state: { username }, getUsername } = useContext(AuthContext);
  const { clearCache } = useContext(AdminContext);
  const { authState, authService } = useOktaAuth();
  const [activeTab, setActiveTab] = useState('');
  const [clearingCache, setClearingCache] = useState(false);
  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setActiveTab(pathname.split('/')[2]);
  }, []);

  const onClearCache = () => {
    setClearingCache(true);
    clearCache()
      .then(response => {
        setClearingCache(false);
        if (response && response.status === 200) {
          addNotification('Success', response.data.message, 'success');
        } else {
          addNotification('Error', 'Could not clear cache', 'danger');
        }
      });
  };

  return (
    <div className="sidebar">
      <ul className="list">
        <li className="item-welcome">
          {username &&
            <p className="welcome">Welcome back, <b>{username}</b>!</p>
          }
        </li>
        <li className="item">
          <Link className={`link ${activeTab === 'homepage' ? 'active' : ''}`} to="/dashboard/homepage">
            Homepage
          </Link>
        </li>
        <li className="item">
          <Link className={`link ${activeTab === 'tracks' ? 'active' : ''}`} to="/dashboard/tracks">
            Tracks
          </Link>
        </li>
        <li className="item">
          <Link className={`link ${activeTab === 'albums' ? 'active' : ''}`} to="/dashboard/albums">
            Albums
          </Link>
        </li>
        <li className="item">
          <Link className={`link ${activeTab === 'artists' ? 'active' : ''}`} to="/dashboard/artists">
            Artists
          </Link>
        </li>
        <li className="item">
          <Link className={`link ${activeTab === 'playlists' ? 'active' : ''}`} to="/dashboard/playlists">
            Playlists
          </Link>
        </li>
        <li className="item">
          <Link className={`link ${activeTab === 'programmes' ? 'active' : ''}`} to="/dashboard/programmes">
            Programmes
          </Link>
        </li>
        <li className="item cache" onClick={onClearCache}>
          <MdCached size={22} className={clearingCache ? 'cache-icon rotating' : 'cache-icon'} />
          <span>Clear Cache</span>
        </li>
      </ul>
    </div>
  );
};

export default DashboardSidebar;
