import React , { useState, useEffect, useContext, useRef } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { GrImage } from 'react-icons/gr';
import { IoMdImages, IoMdTrash } from 'react-icons/io';
import { DotLoader } from 'react-spinners';
import moment from 'moment';
import { EditorState, ContentState } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML, convertFromHTML } from 'draft-convert';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DOMPurify from 'dompurify';

import '../../styles/scss/components/date-picker.scss';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Context as PlaylistsContext } from '../../context/PlaylistsContext';
import { Context as ProgrammesContext } from '../../context/ProgrammesContext';
import { Context as TracksContext } from '../../context/TracksContext';
import { Context as ArtistsContext } from '../../context/ArtistsContext';
import { Context as AlbumsContext } from '../../context/AlbumsContext';
import { addNotification } from '../../helpers/addNotification';

const PlaylistForm = ({ closeModal, managerState, currentItem }) => {
  const { getPlaylistsManager, savePlaylist, updatePlaylist } = useContext(PlaylistsContext);
  const { state: { programmes }, getProgrammes } = useContext(ProgrammesContext);
  const { searchTracks } = useContext(TracksContext);
  const { searchArtists } = useContext(ArtistsContext);
  const { searchAlbums } = useContext(AlbumsContext);
  const [date, setDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date('1998-01-01'));
  const [isSaving, setIsSaving] = useState(false);
  const [arrayError, setArrayError] = useState(false);
  const [linkError, setLinkError] = useState(false);
  const [formArrayTouched, setFormArrayTouched] = useState(false);
  const [playlistArray, setPlaylistArray] = useState([]);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(0);
  const [currentIntroImage, setCurrentIntroImage] = useState(null);
  const [newIntroImage, setNewIntroImage] = useState(null);
  const [progLetter, setProgLetter] = useState('none');
  const [isProgLetterRequired, setIsProgLetterRequired] = useState(false);
  const [introTitle, setIntroTitle] = useState('');
  const [introDescription, setIntroDescription] = useState('');
  const [descriptionCredits, setDescriptionCredits] = useState('');
  const [imageCredits, setImageCredits] = useState('');
  const [purchaseButton, setPurchaseButton] = useState(false);
  const [purchaseLink, setPurchaseLink] = useState('');
  const [purchaseText, setPurchaseText] = useState('Buy a signed copy');
  const [alternativePurchaseText, setAlternativePurchaseText] = useState('AN AUTOGRAPHED COPY OF THIS PLAYLIST IS AVAILABLE TO PURCHASE CLICK TO HERE TO MAKE AN ENQUIRY');
  const fileInputRef = useRef(null);
  const autoTrackRef = useRef(null);
  const autoAlbumRef = useRef(null);
  const autoArtistRef = useRef(null);
  const focusedInputRef = useRef(null);
  const upToDatePlaylistArr = useRef(playlistArray);
  // Create rich text editor values
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [convertedContent, setConvertedContent] = useState(null);
  useEffect(() => {
    if (programmes.length === 0) {
      getProgrammes();
    }
    // SET INITIAL VALUES IF UPDATE
    if (currentItem) {
      setProgLetter(currentItem.progcode.slice(0, -8));
      if (currentItem.intro) {
        if (currentItem.intro.image) setCurrentIntroImage(currentItem.intro.image);
        if (currentItem.intro.title) setIntroTitle(currentItem.intro.title);
        if (currentItem.intro.description) {
          setIntroDescription(currentItem.intro.description);
          // Get the HTML code and process it to display it as rich text so it can be editable
          setEditorState(
            EditorState.createWithContent(
              convertFromHTML({
                htmlToEntity: (nodeName, node, createEntity) => {
                  if (nodeName === "a") {
                    return createEntity("LINK", "MUTABLE", {
                      url: node.href,
                    });
                  }
                },
              })(currentItem.intro.description)
            )
          );
        }
        if (currentItem.intro.description_credits)
        if (currentItem.intro.description_credits) setDescriptionCredits(currentItem.intro.description_credits);
        if (currentItem.intro.image_credits) setImageCredits(currentItem.intro.image_credits);
        if (currentItem.intro.purchase_link) setPurchaseLink(currentItem.intro.purchase_link);
        if (currentItem.intro.purchase_text) setPurchaseText(currentItem.intro.purchase_text);
        if (currentItem.intro.alternative_purchase_text) setAlternativePurchaseText(currentItem.intro.alternative_purchase_text);
        if (currentItem.intro.purchase_button === 1) setPurchaseButton(true);
      }
      
      setDate(new Date(currentItem.date));
      setPlaylistArray(
        currentItem.playlistArr.map(item => ({
          trackTitle: {
            value: item.track?.tracktitle,
            valid: true,
            isOpen: false,
            exists: true
          },
          albumTitle: {
            value: item.album?.title,
            valid: true,
            isOpen: false,
            exists: true
          },
          artistName: {
            value: item.artist?.name,
            valid: true,
            isOpen: false,
            exists: true
          }
        })
        )
      );
    }
    document.addEventListener('mousedown', onClickOutside);

    return () => {
      document.removeEventListener('mousedown', onClickOutside);
    };
  }, []);

  useEffect(() => {
    upToDatePlaylistArr.current = playlistArray;
  }, [playlistArray])

  // Function that receives the actual state and updates the EditorState
  // and converts the current content state into HTML
  const onEditorStateChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  // Function that converts the current state into HTML (For now only links and paragraphs)
  // And sets the description value into it
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === "LINK") {
          if(entity.data.targetOption === '_blank'){
            return <a href={entity.data.url} target='_blank' />; // originalText is escaped once
          }
          else{
            return <a href={entity.data.url} />; // originalText is escaped once
          }
        }
        return originalText;
      },
    })(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
    setIntroDescription(currentContentAsHTML);
  };

  const OnDragEnd = fields => result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let playlistItems = playlistArray;
    // Insert the item in the new position and move the other items
    playlistItems.splice(result.destination.index, 0, playlistItems.splice(result.source.index, 1)[0]);
    // Save the new playlist order
    setPlaylistArray(playlistItems);
    // Swap the fields so the change is also visual
    fields.swap(result.source.index, result.destination.index);
  };

  const onProgLetterChange = (e) => {
    if (e.target.value) {
      setIsProgLetterRequired(false);
    }
    setProgLetter(e.target.value);
  };

  const fileChangedHandler = (event) => {
    setNewIntroImage(event.target.files[0]);
  };

  const deleteIntroImage = (type, image) => {
    if (type === 'new') {
      setNewIntroImage(null);
    }
    if (type === 'current') {
      setCurrentIntroImage(null);    
    }
  };

  const onAddTrack = () => {
    setPlaylistArray([ 
      ...playlistArray, 
      {
        trackTitle : { 
          value: '', 
          valid: true,
          isOpen: false,
          exists: true
        },
        albumTitle: {
          value: '',
          valid: true,
          isOpen: false,
          exists: true
        },
        artistName: {
          value: '',
          valid: true,
          isOpen: false,
          exists: true
        }
      }
    ]);
  };

  const onRemoveTrack = (index) => {
    setPlaylistArray(playlistArray.filter(item => item !== playlistArray[index]));
  };

  const onTypeTrack = (value, index) => {
    if (value) {
      searchTracks(value, false)
        .then(response => {
          setSearchSuggestions(response);
        });
    } else {
      setSearchSuggestions([]);
    }

    let newArray = [ ...playlistArray ];
    newArray[index].trackTitle = { 
      ...newArray[index].trackTitle, 
      value,
      valid: value ? true : false,
      isOpen: value ? true : false,
      // exists: value ? 
    };
    setPlaylistArray(newArray);
  };

  const onTypeArtist = (value, index) => {
    if (value) {
      searchArtists(value, false)
        .then(response => setSearchSuggestions(response));
    } else {
      setSearchSuggestions([]);
    }

    let newArray = [ ...playlistArray ];
    newArray[index].artistName = { 
      ...newArray[index].artistName, 
      value: value,
      valid: value ? true : false,
      isOpen: value ? true : false
    };
    setPlaylistArray(newArray);
  };

  const onTypeAlbum = (value, index) => {
    if (value) {
      searchAlbums(value, false)
        .then(response => setSearchSuggestions(response));
    } else {
      setSearchSuggestions([]);
    }

    let newArray = [ ...playlistArray ];
    newArray[index].albumTitle = { 
      ...newArray[index].albumTitle, 
      value: value,
      valid: value ? true : false,
      isOpen: value ? true : false
    };
    setPlaylistArray(newArray);
  };

  const onKeyDown = (event) => {
    if (event.keyCode === 40) {
      if (activeDropdownIndex < searchSuggestions.length - 1) {
        setActiveDropdownIndex(activeDropdownIndex + 1);
      } else {
        setActiveDropdownIndex(0);
      }
    }
  };

  const onKeyUp = (event) => {
    if (event.keyCode === 38) {
      if (activeDropdownIndex > 0) {
        setActiveDropdownIndex(activeDropdownIndex - 1);
      } else {
        setActiveDropdownIndex(searchSuggestions.length - 1);
      }
    }
  };

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      let onSelectFnc;
      let nameParts = event.target.name.split('.');
      if (nameParts[1] === 'trackTitle') onSelectFnc = onSelectTrack;
      if (nameParts[1] === 'artistName') onSelectFnc = onSelectArtist;
      if (nameParts[1] === 'albumTitle') onSelectFnc = onSelectAlbum;
      onSelectFnc(
        searchSuggestions[activeDropdownIndex],  
        nameParts[0].length === 9 ? 
          nameParts[0].slice(-2, -1) : 
          nameParts[0].slice(-3, -1)
      );

      setActiveDropdownIndex(0);
      setSearchSuggestions([]);
    }
  };

  const onSelectTrack = (track, index) => {
    setSearchSuggestions([]);
    let newArray = [ ...playlistArray ];
    newArray[index] = { 
      ...newArray[index], 
      trackTitle: {
        value: track.tracktitle,
        valid: true,
        isOpen: false,
        exists: true
      },
      albumTitle: {
        value: track.album.title,
        valid: true,
        isOpen: false,
        exists: true
      },
      artistName: {
        value: track.artist.name,
        valid: true,
        isOpen: false,
        exists: true
      }
    };
    setPlaylistArray(newArray);
  };

  const onSelectAlbum = (album, index) => {
    setSearchSuggestions([]);
    let newArray = [ ...playlistArray ];
    newArray[index] = { 
      ...newArray[index], 
      albumTitle: {
        value: album.title,
        valid: true,
        isOpen: false,
        exists: true
      },
      artistName: {
        value: album.artist.name,
        valid: true,
        isOpen: false,
        exists: true
      }
    };
    setPlaylistArray(newArray);
  };

  const onSelectArtist = (artist, index) => {
    setSearchSuggestions([]);
    let newArray = [ ...playlistArray ];
    newArray[index].artistName = { 
      ...newArray[index].artistName, 
      value: artist.name,
      valid: true,
      isOpen: false,
      exists: true
    };
    setPlaylistArray(newArray);
  };

  const onFocus = (e) => {
    if (e.target.name) {
     focusedInputRef.current = e.target.name;
    }
  };

  const onBlur = (type, index) => {
    let searchFnc;

    let newArray = [ ...playlistArray ];
    newArray[index][type]= {
      ...newArray[index][type],
      valid: playlistArray[index][type].value === '' ? false : true
    }

    if (type === 'trackTitle') searchFnc = searchTracks;
    if (type === 'albumTitle') searchFnc = searchAlbums;
    if (type === 'artistName') searchFnc = searchArtists;

    if (newArray[index][type].value) {
      searchFnc(newArray[index][type].value, true)
        .then(response => {
          newArray[index][type]= {
            ...newArray[index][type],
            exists: response.length === 0 ? false : true
          };
          setPlaylistArray(newArray);
        });
    } else {
      newArray[index][type]= {
        ...newArray[index][type],
        exists: true
      };
      setPlaylistArray(newArray);
    }

    focusedInputRef.current = null;
  };

  const onClickOutside = (e) => {
    if (autoTrackRef.current && autoTrackRef.current.contains(e.target)) return;
    if (autoAlbumRef.current && autoAlbumRef.current.contains(e.target)) return;
    if (autoArtistRef.current && autoArtistRef.current.contains(e.target)) return;

    if (focusedInputRef.current) {
      let newArray = upToDatePlaylistArr.current.map(item => {
        return {
          ...item,
          trackTitle: {
            ...item.trackTitle,
            isOpen: false
          },
          albumTitle: {
            ...item.albumTitle,
            isOpen: false
          },
          artistName: {
            ...item.artistName,
            isOpen: false
          }
        };
      });
      setPlaylistArray(newArray);
      setSearchSuggestions([]);
    }
  };

  const onSubmit = async (formData) => {

    convertContentToHTML();

    // VALIDATION
    if (progLetter === 'none') {
      setIsProgLetterRequired(true);
      return;
    }

    if (upToDatePlaylistArr.current.length == 0) {
      setArrayError(true);
      return;
    } else {
      setArrayError(false);
    }

    if(purchaseButton && (purchaseText === '' || purchaseLink === '')){
      setLinkError(true);
      return;
    }else{
      setLinkError(false);
    }

    let newArray = playlistArray.map(item => {
      return {
        ...item,
        trackTitle: {
          ...item.trackTitle,
          valid: item.trackTitle.value ? true : false
        },
        albumTitle: {
          ...item.albumTitle,
          valid: item.albumTitle.value ? true : false
        },
        artistName: {
          ...item.artistName,
          valid: item.artistName.value ? true : false
        }
      };
    });
    setPlaylistArray(newArray);

    for (let pair of playlistArray) {
      if (!pair.trackTitle.value) {
        return;
      }
      if (!pair.albumTitle.value) {
        return;
      }
      if (!pair.artistName.value) {
        return;
      }
    }

    setIsSaving(true);

    let playlistData = new FormData();

    if (currentItem && 
         (currentItem.date !== moment.utc(date).format('YYYY-MM-DD') || 
          progLetter !== currentItem.progcode.slice(0, -8))) {
      playlistData.append('playlistChanged', true);
      playlistData.append('oldProgCode', currentItem.progcode);
    }

    if (currentItem?.intro?.image && !currentIntroImage) {
      formData.image = '';
      playlistData.append('deletedIntroImage', currentItem.intro.image);
    }

    if (currentItem) {
      playlistData.append('image', currentItem.intro?.image);
    }

    playlistData.append('progletter', progLetter);
    playlistData.append('date', date);
    playlistData.append('title', introTitle);
    playlistData.append('description', introDescription);
    playlistData.append('description_credits', descriptionCredits);
    playlistData.append('image_credits', imageCredits);
    playlistData.append('purchase_link', purchaseLink);
    playlistData.append('purchase_text', purchaseText);
    playlistData.append('alternative_purchase_text', alternativePurchaseText);
    playlistData.append('purchase_button', purchaseButton);

    if (newIntroImage) {
      playlistData.append(
        'introImage',
        newIntroImage,
        newIntroImage.name
      );
    }

    playlistData.append('tracks', JSON.stringify(playlistArray.map(item => {
      return (
        {
          trackTitle: item.trackTitle.value,
          albumTitle: item.albumTitle.value,
          artistName: item.artistName.value
        }
      );
    })));

    const actionFnc = currentItem ? updatePlaylist : savePlaylist;
    let title, type, message;
    actionFnc(playlistData)
      .then(response => {
        getPlaylistsManager([managerState().startDate, managerState().endDate], managerState().programme, managerState().page)
          .then(result => {
            setIsSaving(false);
            closeModal();
            if (response.status === 200) {
              title = 'Success!';
              type = 'success';
               message = response.data.message;
              addNotification(title, message, type);
            }
          });
      })
      .catch(err => {
        setIsSaving(false);
        if (err.response.status === 422) {
          title = 'Error!'  
          type = 'danger';
          message = err.response.data.message;
          addNotification(title, message, type);
        }
      });
  };

  return (
    <Form
      onSubmit={onSubmit}
        initialValues={
          currentItem ? 
          {
            tracks: playlistArray.map(item => {
              return {
                ...item
              } 
            })
          } :
          {}
      }
      mutators={{
        ...arrayMutators
      }}
      render={({
        handleSubmit, 
        form: {
          mutators: { push, pop }
        }, 
        submitting, 
        form,
        pristine, 
        reset }) => (
        <form className="dashboard-form" onSubmit={handleSubmit}>
          <Field name="progletter">
            {({ select, meta }) => (
              <div className="input-group">
                <div className="input-group-header">
                  <label htmlFor="programme" className="label">BBC Programme</label>
                  {isProgLetterRequired && <span className="error input-type">Required</span>}
                </div>
                <select onChange={onProgLetterChange} id="programme" { ...select } value={progLetter}>
                  <option value="none">Select Programme</option>
                  {programmes.map(item => (
                    <option key={item.ID} value={item.shortcode}>{item.Programme}</option>
                  ))}
                </select>
              </div>
            )} 
          </Field>
          <div className="input-group">
            <label htmlFor="date" className="label">Programme Date</label>
            <DatePicker
              dateFormat="dd/MM/yyyy"
              id="date"
              selected={date}
              onChange={setDate}
              popperClassName="date-input"
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={10}
              adjustDateOnChange
            />
          </div>
          <Field name="title">
            {({ input, meta }) => (
              <div className="input-group">
                <div className="input-group-header">
                  <label htmlFor="title" className="label">Title</label>
                  <span className="input-type"> Optional</span>
                </div>
                <input
                  { ...input }
                  id="title"
                  type="text" 
                  placeholder="Title" 
                  value={introTitle}
                  onChange={(e) => setIntroTitle(e.target.value)}
                /> 
              </div>
            )}
          </Field>
          <Field name="description">
            {({ input, meta }) => (
              <div className="input-group">
                <div className="input-group-header">
                  <label htmlFor="description" className="label">Description</label>
                  <span className="input-type"> Optional</span>
                </div>
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  onChange={convertContentToHTML}
                  toolbarClassName="editorToolbar"
                  wrapperClassName="editorWrapper"
                  editorClassName="editorEditor"
                  toolbar={{
                    options: ["link"],
                  }}
                />
              </div>
            )}
          </Field>
          <Field name="description_credits">
            {({ input, meta }) => (
              <div className="input-group">
                <div className="input-group-header">
                  <label htmlFor="descriptionCredits" className="label">Description credits</label>
                  <span className="input-type"> Optional</span>
                </div>
                <input
                  { ...input }
                  id="descriptionCredits"
                  type="text" 
                  placeholder="Description credits" 
                  value={descriptionCredits}
                  onChange={(e) => setDescriptionCredits(e.target.value)}
                /> 
              </div>
            )}
          </Field>
          <div className="input-group">
            <div className="input-group-header">
              <label htmlFor="introImage" className="label">Intro Image</label>
              <span className="input-type"> Optional</span>
            </div>
            {newIntroImage && 
              <div className="img-container img-container-big">         
                <div onClick={() => deleteIntroImage('new', newIntroImage)} className="delete-banner">
                  <IoMdTrash className="trash-icon" size={25} />
                  <div className="delete-banner-background"></div>
                </div>
                <img 
                  src={URL.createObjectURL(newIntroImage)} 
                  onClick={() => fileInputRef.current.click()} 
                  className="playlist-intro-image" 
                />
              </div>
            }
            {currentIntroImage && !newIntroImage &&
              <div className="img-container img-container-big">   
                <div onClick={() => deleteIntroImage('current', currentIntroImage)} className="delete-banner">
                  <IoMdTrash className="trash-icon" size={25} />
                  <div className="delete-banner-background"></div>
                </div>      
                <img 
                  src={`${process.env.REACT_APP_API_BASE_URL}/public/images/playlists/intro/${currentIntroImage}`} 
                  onClick={() => fileInputRef.current.click()}
                  className="playlist-intro-image" 
                />
              </div>
            }
            {!currentIntroImage && !newIntroImage && <div onClick={() => fileInputRef.current.click()} className="image-placeholder playlist-image-placeholder">
                <GrImage size={45} className="placeholder-icon" />
              </div>
            } 
            <Field name="image">
              {props => (
                <div className="input-group">
                  <input 
                    ref={fileInputRef}
                    className="file-input"
                    type="file" 
                    accept="image/*" 
                    id="introImage"
                    onChange={fileChangedHandler} />
                  <span className="instructions">
                    <p>
                      Image will be displayed at the top of a playlist page in the playlist introductory section.
                    </p>
                  </span>
                </div>
              )}
            </Field>
            <Field name="image_credits">
            {({ input, meta }) => (
              <div className="input-group">
                <div className="input-group-header">
                  <label htmlFor="imageCredits" className="label">Image credits</label>
                  <span className="input-type"> Optional</span>
                </div>
                <input
                  { ...input }
                  id="imageCredits"
                  type="text" 
                  placeholder="Image credits" 
                  value={imageCredits}
                  onChange={(e) => setImageCredits(e.target.value)}
                /> 
              </div>
            )}
          </Field>
            <Field name="purchase_button" type="checkbox" >
              {({ input, meta }) => (
                <div className="input-group">
                  <div className="display-flex align-items-vertically">
                    <label htmlFor="purchase" className="label">Add signed copy purchase button</label>
                    <input
                      { ...input }
                      id="purchase"
                      checked={purchaseButton}
                      onClick={() => setPurchaseButton(!purchaseButton)}
                      className="checkbox"
                    /> 
                  </div>
                  <span className="instructions">
                    <p>Selecting the checkbox adds a signed copy of the playlist purchase CTA button on the playlist page. If no link is provided the button won't be shown.</p>
                  </span>
                </div>
              )}
            </Field>
            {purchaseButton && <>
              <Field name="purchase_link">
              {({ input, meta }) => (
                <div className="input-group">
                  <div className="input-group-header">
                    <label htmlFor="purchaseLink" className="label">Purchase link</label>
                  </div>
                  <input
                    { ...input }
                    id="purchaseLink"
                    type="text" 
                    placeholder="Purchase link" 
                    value={purchaseLink}
                    onChange={(e) => setPurchaseLink(e.target.value)}
                  /> 
                  {linkError && <span className="error error-array input-type">Required</span>}
                </div>
              )}
              </Field>
              <Field name="purchase_text">
                {({ input, meta }) => (
                  <div className="input-group">
                    <div className="input-group-header">
                      <label htmlFor="purchaseText" className="label">Purchase link text</label>
                    </div>
                    <input
                      {...input}
                      id="purchaseText"
                      type="text"
                      placeholder="Purchase link text"
                      value={purchaseText}
                      onChange={(e) => setPurchaseText(e.target.value)}
                    />
                    {linkError && <span className="error error-array input-type">Required</span>}
                  </div>
                )}
              </Field>
            </>}
          </div>
          <Field name="alternative_purchase_text">
            {({ input, meta }) => (
              <div className="input-group">
                <div className="input-group-header">
                  <label htmlFor="alternativePurchaseText" className="label">Alternative purchase text</label>
                </div>
                <span className="instructions">
                  <p>Selecting the checkbox adds a signed copy of the playlist purchase CTA button on the playlist page. If no link is provided the button won't be shown.</p>
                </span>
                <input
                  {...input}
                  id="alternativePurchaseText"
                  type="text"
                  placeholder="Alternative purchase text"
                  value={alternativePurchaseText}
                  onChange={(e) => setAlternativePurchaseText(e.target.value)}
                />
                {linkError && <span className="error error-array input-type">Required</span>}
              </div>
            )}
          </Field>
          <div className="input-group">
            <div className="input-group-header">
              <label className="label">Playlist Tracks</label>  
              <button 
                className="button button-small positive-button margin-right-small" 
                type="button" 
                onClick={() => {
                  push('tracks', undefined);
                  onAddTrack();
                }}
              >
                Add Track
              </button>
              {arrayError && <span className="error error-array input-type">Required</span>}
            </div>
            <span className="instructions">
              <p>
                Empty playlist track fields are not allowed. Please make sure all tracks are given values before submitting your playlist.
              </p>
            </span>
            <FieldArray name="tracks">
              {({ fields, meta }) => {
                if (meta.touched) {
                  setFormArrayTouched(true);
                }
                return (
                <DragDropContext onDragEnd={OnDragEnd(fields)}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {fields.map((name, index) => ( // Return goes here
                              <Draggable
                                key={name}
                                draggableId={name}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="field-array-field" key={name}
                                  >
                                    <Field id={`${name}.trackTitle`} name={`${name}.trackTitle`} >
                                          {({ input, meta }) => (
                                            <div className="input-group-array">
                                              <span className="input-group-header">
                                                <label htmlFor={`${name}.trackTitle`}>Track #{index + 1}</label>
                                                <button
                                                  onClick={() => {
                                                    onRemoveTrack(index);
                                                    fields.remove(index);
                                                  }}
                                                  className="button button-small delete-button align-right"
                                                  type="button">Remove
                                              </button>
                                              </span>
                                              <input
                                                id={`${name}.trackTitle`}
                                                {...input}
                                                type="text"
                                                placeholder="Track Name"
                                                onChange={(event) => onTypeTrack(event.target.value, index)}
                                                value={playlistArray[index]?.trackTitle.value || ''}
                                                autoComplete="off"
                                                onFocus={(e) => onFocus(e)}
                                                onBlur={() => onBlur('trackTitle', index)}
                                                onKeyDown={(e) => onKeyDown(e)}
                                                onKeyPress={(e) => onKeyPress(e)}
                                                onKeyUp={(e) => onKeyUp(e)}
                                              />
                                              <div className="input-group-footer">
                                                {!playlistArray[index]?.trackTitle.valid && <span className="error error-array-field input-type">Required</span>}
                                                {!playlistArray[index]?.trackTitle.exists &&
                                                  <div className="group-array-notification">
                                                    Track does not exist. A new Track will be created and assigned to the respective album.
                                                </div>
                                                }
                                              </div>
                                              {playlistArray[index]?.trackTitle.isOpen && searchSuggestions && searchSuggestions.length > 0 &&
                                                <div ref={autoTrackRef} className="autocomplete autocomplete-big">
                                                  <ul className="list">
                                                    {searchSuggestions.map((item, trackIndex) => (
                                                      <li
                                                        onClick={() => onSelectTrack(item, index)}
                                                        className={activeDropdownIndex === trackIndex ? "item active" : "item"}
                                                        key={item.trackid}
                                                      >
                                                        {item.artist?.wwwimage ?
                                                          <img
                                                            className="img"
                                                            src={`${process.env.REACT_APP_API_BASE_URL}/public/images/artists/covers/original/${item.artist.wwwimage}`} /> :
                                                          <div className="img-container">
                                                            <IoMdImages size={45} className="placeholder-icon" />
                                                          </div>
                                                        }
                                                        <div className="item-info">
                                                          <span className="primary">{item.tracktitle}</span>
                                                          <span className="secondary">{item.artist?.name} - {item.album?.title}</span>
                                                        </div>
                                                      </li>
                                                    ))}
                                                  </ul>
                                                </div>
                                              }
                                            </div>
                                          )}
                                      </Field>
                                      <Field name={`${name}.albumTitle`}>
                                          {({ input, meta }) => (
                                            <div className="input-group-array">
                                              <input
                                                {...input}
                                                type="text"
                                                placeholder="Album Title"
                                                autoComplete="off"
                                                onChange={(event) => onTypeAlbum(event.target.value, index)}
                                                value={playlistArray[index]?.albumTitle.value || ''}
                                                onFocus={(e) => onFocus(e)}
                                                onBlur={() => onBlur('albumTitle', index)}
                                                onKeyDown={(e) => onKeyDown(e)}
                                                onKeyPress={(e) => onKeyPress(e)}
                                                onKeyUp={(e) => onKeyUp(e)}
                                              />
                                              <div className="input-group-footer">
                                                {!playlistArray[index]?.albumTitle.valid && <span className="error error-array-field input-type">Required</span>}
                                                {!playlistArray[index]?.albumTitle.exists &&
                                                  <div className="group-array-notification">
                                                    Album does not exist. A new Album will be created and assigned to the respective artist.
                                                </div>
                                                }
                                              </div>
                                              {playlistArray[index]?.albumTitle.isOpen && searchSuggestions && searchSuggestions.length > 0 &&
                                                <div ref={autoAlbumRef} className="autocomplete autocomplete-big autocomplete-less-margin">
                                                  <ul className="list">
                                                    {searchSuggestions.map((item, albumIndex) => (
                                                      <li
                                                        onClick={() => onSelectAlbum(item, index)}
                                                        className={activeDropdownIndex === albumIndex ? "item active" : "item"}
                                                        key={item.albumid}
                                                      >
                                                        {item.url ?
                                                          <img
                                                            className="img"
                                                            src={`http://images.amazon.com/images/P/${item.url}`}
                                                            alt="" /> :
                                                          <div className="img-container">
                                                            <IoMdImages size={45} className="placeholder-icon" />
                                                          </div>
                                                        }
                                                        <div className="item-info">
                                                          <span className="primary">{item.title}</span>
                                                          <span className="secondary">{item.artist?.name}</span>
                                                        </div>
                                                      </li>
                                                    ))}
                                                  </ul>
                                                </div>
                                              }
                                            </div>
                                          )}
                                      </Field>
                                      <Field name={`${name}.artistName`}>
                                        {({ input, meta }) => (
                                          <div className="input-group-array">
                                            <input
                                              {...input}
                                              type="text"
                                              placeholder="Artist Name"
                                              autoComplete="off"
                                              onChange={(event) => onTypeArtist(event.target.value, index)}
                                              value={playlistArray[index]?.artistName.value || ''}
                                              onFocus={(e) => onFocus(e)}
                                              onBlur={() => onBlur('artistName', index)}
                                              onKeyDown={(e) => onKeyDown(e)}
                                              onKeyPress={(e) => onKeyPress(e)}
                                              onKeyUp={(e) => onKeyUp(e)}
                                            />
                                            <div className="input-group-footer">
                                              {!playlistArray[index]?.artistName.valid && <span className="error error-array-field input-type">Required</span>}
                                              {!playlistArray[index]?.artistName.exists &&
                                                <div className="group-array-notification">
                                                  Artist does not exist. A new Artist will be created.
                                              </div>
                                              }
                                            </div>
                                            {playlistArray[index]?.artistName.isOpen && searchSuggestions && searchSuggestions.length > 0 &&
                                              <div ref={autoArtistRef} className="autocomplete autocomplete-big autocomplete-less-margin">
                                                <ul className="list">
                                                  {searchSuggestions.map((item, artistIndex) => (
                                                    <li
                                                      onClick={() => onSelectArtist(item, index)}
                                                      className={activeDropdownIndex === artistIndex ? "item active" : "item"}
                                                      key={item.artistid}
                                                    >
                                                      {item.wwwimage ?
                                                        <img
                                                          className="img"
                                                          src={`${process.env.REACT_APP_API_BASE_URL}/public/images/artists/covers/original/${item.wwwimage}`}
                                                          alt="" /> :
                                                        <div className="img-container">
                                                          <IoMdImages size={45} className="placeholder-icon" />
                                                        </div>
                                                      }
                                                      <div className="item-info">
                                                        <span className="primary">{item.name}</span>
                                                      </div>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </div>
                                            }
                                          </div>
                                        )}
                                      </Field>
                                  </div>
                                )}
                              </Draggable>
                          ))
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                )
              }
            }
            </FieldArray>

          </div>
          <div className="buttons">
            <button
              className="button dismiss-button" 
              type="button"
              onClick={() => {
                form.reset();
                setDate(new Date());
              }}
              disabled={submitting || pristine}
            >
              Reset
            </button>
            <button 
              className={isSaving ? 'button action-button submit-button in-progress' : 'button action-button submit-button'} 
              type="submit" 
              disabled={submitting || isSaving}
            >
              {isSaving && <div className="sweet-loading">
                <DotLoader 
                  size={20}
                  color={"#fff"}
                  loading={isSaving}
                />
              </div>}
              <span>{isSaving ? 'Submitting...' : 'Submit'}</span>
            </button>
          </div>
        </form>
      )} />
    );
};

export default PlaylistForm;

