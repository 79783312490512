import React from 'react';

const CustomImage = (props) => {
  const { img, type, alt, classNameWrapper, classNameImg, onLoad, onClick } = props;
  let imgRoot;

  if (type === 'local') {
    imgRoot = process.env.REACT_APP_API_BASE_URL;
  } else {
    imgRoot = `${process.env.REACT_APP_API_BASE_URL}/public/images/`;
  }

  const renderImgContainer = () => {
    if (window.CSS && CSS.supports && CSS.supports('object-fit', 'cover')) {
      return {
        display: 'blcok'
      };
    } else {
      return {
        backgroundImage: `url('${imgRoot}${img}')`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      };
    }
  };

  const renderImg = () => {
    if (window.CSS && CSS.supports && CSS.supports('object-fit', 'cover')) {
      return {
        display: 'block'
      };
    } else {
      return {
        display: 'none'
      };
    }
  };

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <div onClick={onClickHandler} className={classNameWrapper} style={renderImgContainer()}>
      <img
        onLoad={onLoad}
        className={classNameImg}
        style={renderImg()}
        src={`${imgRoot}${img}`}
        alt={alt} />
    </div>
  );
}; 

export default CustomImage;