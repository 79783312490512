import createDataContext from './createDataContext';

import API from '../api/api';

const adminReducer = (state, action) => {
  switch (action.type) {
    default: 
      return state;
  }
};

const clearCache = dispatch => async () => {
  try {
    const response = await API.get('/clear-cache');

    return response;
  } catch(err) {
    console.log(err);
    console.log(err.response)
    if (err.response && err.response.data) {
      console.log(err.response.data);
    }
  }
};

export const { Context, Provider } = createDataContext(
  adminReducer,
  { clearCache },
  { }
);